import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import CITY from '../../assets/img/city.jpg'

import './styles.scss'

const ManagerHeader = () => {
  const { t } = useTranslation()

  const location = useLocation()

  return (
    <div className='manager'>
      <img alt='' src={CITY} />
      <span>
        <img alt='' src={CITY} />
      </span>
      <div className='manager__row'>
        <div className='manager__title'>
          <span>{t('building-manager')}</span>
          <span>{localStorage.getItem('title')}</span>
        </div>
        {location.pathname !== '/dashboard' && (
          <div className='manager__button'>
            <Link to='/dashboard' type='button'>
              <span>{t('dashboard')}</span>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default ManagerHeader

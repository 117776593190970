import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import FLAGEN from '../../assets/img/flags/en.png'
import FLAGFA from '../../assets/img/flags/fa.png'

const Flags = () => {
  const {
    params: { lang = 'fa' },
    path,
  } = useRouteMatch()

  const newLang = lang === 'fa' ? 'en' : lang === 'en' ? 'fa' : ''

  return (
    <div className='flags'>
      {lang === 'en' ? (
        <Link className='btn-lang' to={path.replace(':lang?', newLang)}>
          <img alt='header' src={FLAGFA} />
        </Link>
      ) : (
        <Link className='btn-lang' to={path.replace(':lang?', newLang)}>
          <img alt='header' src={FLAGEN} />
        </Link>
      )}
    </div>
  )
}

export default Flags

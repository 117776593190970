// const _URLSearchParams = require('url').URLSearchParams
import { compile } from 'path-to-regexp'

import APIEndpointConstants from './ApiEndpointConstants'

class APIRouteManager {
  constructor() {
    this.BASE__URL = 'https://api.ibillding.com/'

    this.pathNames = {
      [APIEndpointConstants.POST_LOGIN]: 'auth/login',
      [APIEndpointConstants.POST_LOGOUT]: 'auth/logout',
      [APIEndpointConstants.POST_REGISTER]: 'auth/register',
      [APIEndpointConstants.GET_BLOG_POST]: 'landing/posts',
      [APIEndpointConstants.GET_COST_BUDGETS]: 'api/budgets',
      [APIEndpointConstants.GET_FACILITIES]: 'api/facilities',
      [APIEndpointConstants.POST_CHECKUSER]: 'auth/check-user',
      [APIEndpointConstants.POST_RESIDENCES]: 'api/residences',
      [APIEndpointConstants.GET_CHARGE_RULES]: 'api/charge-rules',
      [APIEndpointConstants.POST_FACILITY]: 'api/facilities/request',
      [APIEndpointConstants.POST_VERIFICATION_CODE]: 'auth/send-code',
      [APIEndpointConstants.GET_ALL_UNITS]: 'api/residences/:id/units',
      [APIEndpointConstants.GET_ALL_COSTS]: `api/residences/:id/bills`,
      [APIEndpointConstants.POST_EDITPROFILE_DATA]: 'api/users/update',
      [APIEndpointConstants.GET_PROFITS]: 'api/residences/:id/profits',
      [APIEndpointConstants.POST_MAKE_COST]: 'api/residences/:id/bills',
      [APIEndpointConstants.GET_USER_RESIDENCES]: 'api/users/residences',
      [APIEndpointConstants.POST_NEW_ANNOUNCEMENTS]: 'api/announcements',
      [APIEndpointConstants.PATCH_ANNOUNCEMENTS]: `api/announcements/:id`,
      [APIEndpointConstants.GET_PAYMENT_TOKEN]: `api/payments/token/:id`,
      [APIEndpointConstants.DELETE_ANNOUNCEMENTS]: `api/announcements/:id`,
      [APIEndpointConstants.POST_OWNER_BLOCK]: 'api/blocks/units/:id/owner',
      [APIEndpointConstants.GET_USER_ANNOUNCEMENTS]: `api/announcements/:id`,
      [APIEndpointConstants.PATCH_OWNER_BLOCK]: 'api/blocks/units/:id/owner',
      [APIEndpointConstants.POST_UNIT_CONFIRM]: 'api/users/units/:id/confirm',
      [APIEndpointConstants.POST_RESIDENT_BLOCK]: 'api/blocks/units/:id/resident',
      [APIEndpointConstants.PATCH_RESIDENT_BLOCK]: 'api/blocks/units/:id/resident',
      [APIEndpointConstants.GET_BUILDING_FACILITIES]: 'api/residences/:id/facilities',
      [APIEndpointConstants.GET_MONTHLY_CHARGE]: 'api/blocks/units/:id/monthly-charge',
      [APIEndpointConstants.GET_FINANCIAL_SAVING]: 'api/residences/:id/financial-savings',
      [APIEndpointConstants.GET_FACILITY_TERMS]: '/api/facility-requests/terms-of-privacy',
      [APIEndpointConstants.GET_RESIDENT_FACILITIES_REQUEST]: 'api/user/facility-requests/:id',
      [APIEndpointConstants.GET_MANAGER_FACILITIES_REQUEST]: 'api/residences/:id/facility-requests',
      [APIEndpointConstants.POST_MANAGER_SET_STATUS]: 'api/residences/:id/facility-requests/set-status',
    }
  }

  getPathname = (_key, type, urlParams) => {
    switch (type) {
      case 'BLOG':
        return `${this.BASE__URL}${this.compileURL(this.pathNames[_key], urlParams)}`
      case 'DASHBOARD':
        return `${this.BASE__URL}${this.compileURL(this.pathNames[_key], urlParams)}`
      case 'AUTH':
        return `${this.BASE__URL}${this.compileURL(this.pathNames[_key], urlParams)}`
      default:
        return `${this.BASE__URL}${this.compileURL(this.pathNames[_key], urlParams)}`
    }
  }

  compileURL = (rawURL, urlParams) => {
    const toPath = compile(rawURL)
    return toPath(urlParams)
  }

  generateSearchQuery = (params) => {
    const _searchParams = new URLSearchParams()

    if (Object.keys(params).length !== 0) {
      Object.entries(params).forEach(([key, val]) => _searchParams.set(key, val))

      return `?${_searchParams.toString()}`
    }
    return ''
  }

  generateRoute = ({ key, type, params = {}, urlParams = {} }) =>
    `${this.getPathname(key, type, urlParams)}${this.generateSearchQuery(params)}`
}

export default new APIRouteManager()

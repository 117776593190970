import React from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import './styles.scss'

import BUILDING from '../../../assets/img/bg.jpg'

export default function SuccessPage({ match }) {
  const { t } = useTranslation()

  const type = match.params[0]

  const location = useLocation()
  const params = new URLSearchParams(location.search)
  return (
    <div className='after-payment-page'>
      <div className={`after-payment-page__header after-payment-page__header--${type}`}>
        <h3>{t(`payment-${type}`)}</h3>
        <div>
          <img src={BUILDING} alt='' />
        </div>
      </div>
      <div className='after-payment-page__title'>
        <span>ساختمان آزادی</span>
        <span>مبلغ شارژ تیر ماه</span>
      </div>
      <div className='after-payment-page__amount'>
        <h3>{`${params.get('amount')}`}</h3>
        <div>تومان</div>
      </div>
      <hr />
      <div className='after-payment-page__detail'>
        <div className='after-payment-page__box'>
          <span>{t('payment-code')}</span>
          <span>{`${params.get('code') ?? '--'}`}</span>
        </div>
        <div className='after-payment-page__box'>
          <span>{t('payment-status')}</span>
          <span>{t(`transaction-${type}`)}</span>
        </div>
      </div>
    </div>
  )
}

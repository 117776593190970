import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import i18n from '../../i18n'

function TitleComponent() {
  const { t } = useTranslation()

  const {
    params: { lang = 'fa' },
  } = useRouteMatch()

  useEffect(() => {
    setTimeout(changeLanguage, 0, lang)
  }, [lang])

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng)
  }

  return (
    <Helmet>
      <title>{t('title')}</title>
      <meta content={t('hero-header')} name='description' />
    </Helmet>
  )
}

export default TitleComponent

/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import Service from '../../Api/Service'

import ManagerHeader from '../../../components/managerHeader'
import ResidentHeader from '../../../components/residentHeader'

import { persianPrice } from '../../utils'

import './styles.scss'

const FinancialSaving = () => {
  const shared = useSelector((state) => state.shared)
  const userType = useSelector((state) => state.shared.userType) || 'manager'

  const [list, setList] = useState([])
  const [total, setTotal] = useState(0)

  const isManager = userType === 'manager'

  async function fetchData() {
    const response = await Service.GET_FINANCIAL_SAVING({ id: shared.residence_id })
    if (+response.status === 200) {
      setList(response.data)
      setTotal(response.total)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])
  return (
    <div>
      {isManager ? <ManagerHeader /> : <ResidentHeader />}
      {isManager ? <FinancialCard list={list} total={total} /> : <FinancialcardResident />}
    </div>
  )
}

export default FinancialSaving

const FinancialCard = (props) => {
  const { list, total } = props
  return (
    <div className='financialSavingPage'>
      <div className='financialSavingPage__header'>
        <span>صندوق ساختمان</span>
      </div>
      <div className='financialSavingPage__banner'>
        <div>
          <div className='financialSavingPage__bannerTitle'>
            <div>موجودی</div>
            <div>صندوق</div>
          </div>
          <div className='financialSavingPage__bannerValue'>
            <span>{persianPrice(total)}</span>
            <span>تومان</span>
          </div>
        </div>
      </div>
      <div className='financialSavingPage__body'>
        <div className='financialSavingPage__bodyHeader'>موجودی هر واحد</div>
        {list?.map((row) => (
          <div className='financialSavingPage__bodyRow' key={row.id}>
            <div className='financialSavingPage__bodyDetails'>
              <div>{row.title}</div>
              <div>{row.userInfo.name}</div>
            </div>
            <div className='financialSavingPage__bodyPrice'>
              <span>{persianPrice(row.credit ? row.credit : 0)}</span>
              <span>تومان</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const FinancialcardResident = () => {
  return (
    <div className='financialSavingPage'>
      <div className='financialSavingPage__header'>
        <span>صندوق ساختمان</span>
      </div>
      <div className='financialSavingPage__banner'>
        <div>
          <div className='financialSavingPage__bannerTitle'>
            <div>
              موجودی صندوق <span>({localStorage.getItem('title')})</span>
            </div>
          </div>
          <div className='financialSavingPage__bannerValue'>
            <span>{persianPrice(300)}</span>
            <span>تومان</span>
          </div>
        </div>
      </div>
      <div className='financialSavingPage__body'>
        <div className='financialSavingPage__bodyHeader'>تراکنش ها</div>

        <div className='financialSavingPage__bodyRow'>
          <div className='financialSavingPage__bodyDetails'>
            <div>واریز</div>
            {/* <div>{row.userInfo.name}</div> */}
          </div>
          <div className='financialSavingPage__bodyPrice'>
            <span>{persianPrice(200)}</span>
            <span>تومان</span>
          </div>
        </div>
        <div className='financialSavingPage__bodyRow'>
          <div className='financialSavingPage__bodyDetails'>
            <div>برداشت</div>
            {/* <div>{row.userInfo.name}</div> */}
          </div>
          <div className='financialSavingPage__bodyPrice'>
            <span>{persianPrice(400)}</span>
            <span>تومان</span>
          </div>
        </div>
        <div className='financialSavingPage__bodyRow'>
          <div className='financialSavingPage__bodyDetails'>
            <div>برداشت</div>
            {/* <div>{row.userInfo.name}</div> */}
          </div>
          <div className='financialSavingPage__bodyPrice'>
            <span>{persianPrice(500)}</span>
            <span>تومان</span>
          </div>
        </div>
        <div className='financialSavingPage__bodyRow'>
          <div className='financialSavingPage__bodyDetails'>
            <div>واریز</div>
            {/* <div>{row.userInfo.name}</div> */}
          </div>
          <div className='financialSavingPage__bodyPrice'>
            <span>{persianPrice(1000)}</span>
            <span>تومان</span>
          </div>
        </div>
      </div>
    </div>
  )
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/button-has-type */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Button from '../../../components/button/Button'

import CARD from '../../../assets/img/icons/card.svg'
import HELP from '../../../assets/img/icons/help.svg'
import CHART from '../../../assets/img/icons/chart.svg'
import MONEY from '../../../assets/img/icons/money.svg'
import NOTIF from '../../../assets/img/icons/notif.svg'
import ADVERTISEMENT from '../../../assets/img/icons/advertisement.svg'

import ResidentHeader from '../../../components/residentHeader'

import './resident.scss'

// simport CITY from '../../../assets/img/city.jpg'
import Service from '../../Api/Service'

export default function Resident(props) {
  const routeMatch = useRouteMatch()

  const prefix = routeMatch.params.lang ? `/${routeMatch.params.lang}` : ''

  const [chargeCost, setchargeCost] = useState(0)
  const { t } = useTranslation()
  const statistics = [
    { id: 0, title: `${t('building-charge')}`, icon: HELP },
    { id: 1, title: `${t('building-cash')}`, icon: MONEY, path: '/financial-saving' },
    { id: 2, title: `${t('anouncemenets')}`, icon: NOTIF, path: '/announcements' },
    { id: 3, title: `${t('activity-report')}`, icon: CHART },
    { id: 4, title: `${t('shared-facilities')}`, icon: CARD, path: '/residence-facilities' },
    { id: 5, title: `${t('advertising')}`, icon: ADVERTISEMENT, path: '/residence-advertising' },
  ]

  const [billId, setBillId] = useState(0)

  useEffect(() => {
    callMonthlyCharge()
  }, [])

  // Redux State
  const unitId = useSelector((state) => state.shared.unitData.id)

  const callMonthlyCharge = async () => {
    const response = await Service.GET_MONTHLY_CHARGE({ id: unitId })
    if (response.status === 200) {
      setchargeCost(response.charge)
      setBillId(response?.bill_id)
    }
  }

  const callPaymentToken = async () => {
    const response = await Service.GET_PAYMENT_TOKEN({ id: billId })
    if (response.url) window.location.assign(response.url)
  }

  return (
    <div className='residence'>
      <div className='residence__box'>
        <ResidentHeader />

        <div className='residence__price'>
          <span>{t('remaining-charge')}:</span>
          <span>
            <b>{chargeCost}</b>
            {t('toman')}
          </span>
        </div>
        <div className='residence__payment'>
          <Button
            handler={() => console.log('object')}
            height='35px'
            label={t('payment-notice')}
            minWidth='50px'
            type='bordered'
            width='130px'
          />

          {!!chargeCost && (
            <Button
              handler={callPaymentToken}
              height='35px'
              label={t('online-payment')}
              minWidth='50px'
              type='primary'
              width='130px'
            />
          )}
        </div>
        <hr />
        <div className='residence__costs'>
          <span>{t('costs-detail-this-month')}</span>
          <div className='residence__costs-box'>
            <div style={{ width: '95px', backgroundColor: '#EB3B5A' }}>25%</div>
            <div style={{ width: '42px', backgroundColor: '#FA8231' }}>12%</div>
            <div style={{ width: '38px', backgroundColor: '#F7B731' }}>8%</div>
            <div style={{ width: '68px', backgroundColor: '#20BF6B' }}>18%</div>
            <div style={{ width: '82px', backgroundColor: '#3867D6' }}>22%</div>
          </div>
          <div className='residence__costDetails'>
            <CostDetails color='#EB3B5A' title='هزینه آب' />
            <CostDetails color='#FA8231' title='هزینه برق' />
            <CostDetails color='#F7B731' title='هزینه گاز' />
            <CostDetails color='blue' title='هزینه تعمیرات' />
            <CostDetails color='#3867D6' title='هزینه باغبانی' />
          </div>
        </div>
        <hr />
        <div className='residence__statistics'>
          {statistics.map((item) => (
            <div key={item.id} onClick={() => props.history.push(`${prefix}${item.path || '/dashboard'}`)}>
              <div className={!item.path ? 'manager__statistics--disable' : ''}>
                <img alt={item.icon} src={item.icon} />
              </div>
              <span>{item.title}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export function CostDetails(props) {
  const { color, title } = props
  return (
    <div className='box'>
      <div style={{ backgroundColor: color }}></div>
      <span>{title}</span>
    </div>
  )
}

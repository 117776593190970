import { useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'

// we implement authorization and base permission here

const Guard = ({ children, route }) => {
  const history = useHistory()
  const routeMatch = useRouteMatch()

  const prefix = routeMatch.params.lang ? `/${routeMatch.params.lang}` : ''

  const authenticated = useSelector((state) => state.auth.isAuth)

  useLayoutEffect(() => {
    if (authenticated && route.guest) {
      const { search } = history.location
      history.replace({ pathname: `${prefix}/switch-building`, search })
    } else if (!authenticated && route.protected) {
      const { search } = history.location
      history.replace({ pathname: `${prefix}/login`, search })
    }
  }, [routeMatch.path])
  return children
}

export default Guard

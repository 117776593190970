/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
import Service from '../../Api/Service'

import React, { useEffect, useState } from 'react'
import './landing.scss'
import { useTranslation } from 'react-i18next'
import { faTwitter, faGithub, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector, useDispatch } from 'react-redux'

import { Link, useRouteMatch } from 'react-router-dom'
import IMAGE from '../../../assets/img/image-14.png'
import LOGO from '../../../assets/img/logo.png'

import ICO01 from '../../../assets/img/facilities/ico-01.png'
import ICO02 from '../../../assets/img/facilities/ico-02.png'
import ICO03 from '../../../assets/img/facilities/ico-03.png'
import ICO04 from '../../../assets/img/facilities/ico-04.png'
import ICO05 from '../../../assets/img/facilities/ico-05.png'
import ICO06 from '../../../assets/img/facilities/ico-06.png'
import ICO07 from '../../../assets/img/facilities/ico-07.png'
import ICO08 from '../../../assets/img/facilities/ico-08.png'
import ICO09 from '../../../assets/img/facilities/ico-09.png'
import ICO10 from '../../../assets/img/facilities/ico-10.png'
import ICO11 from '../../../assets/img/facilities/ico-11.png'
import ICO12 from '../../../assets/img/facilities/ico-12.png'
import ICO13 from '../../../assets/img/facilities/ico-13.png'
import ICO14 from '../../../assets/img/facilities/ico-14.png'
import Flags from '../../../components/flags'

function Landing() {
  const { t } = useTranslation()

  const {
    params: { lang = 'fa' },
  } = useRouteMatch()

  const direction = lang === 'en' ? 'left' : 'right'

  // Redux actions
  const dispatch = useDispatch()

  // Redux State
  const state = useSelector((state) => state)
  const isAuth = useSelector((state) => state.auth.isAuth)

  const [posts, setPosts] = useState(null)
  useEffect(() => {
    callPostsApi()
  }, [])

  const callPostsApi = async () => {
    const response = await Service.GET_BlogPosts({ per_page: 3, lang: lang })
    setPosts(response?.data)

  }

  return (
    <div className={`landing landing-${direction}`}>
      <header className='header' id='header'>
        <div className='header-body'>
          <div className='header-container container'>
            <div className='row header-row'>
              <div className='header-logo'>
                <Link to={`/${lang}`}>
                  <img alt='building' className='logo' src={LOGO} />
                </Link>
              </div>

              <nav className='headerMenu'>
                <div className='openMobSide'>
                  <i className='far fa-times-circle'></i>
                </div>
                <ul className='nav' id='mainNav'>
                  <li>
                    <a href='#section1'> {t('home')} </a>
                  </li>
                  <li>
                    <a href='#section2'> {t('services')} </a>
                  </li>
                  <li>
                    <a href='#section3'> {t('specifications')}</a>
                  </li>
                  <li>
                    <a href='#section4'>{t('blog')}</a>
                  </li>
                </ul>
              </nav>
              <div className='cta_header'>
                <Flags />
                <Link className={`btn btn-border m${direction.charAt(0)}-3`} to='home'>
                  {!isAuth ? t('sign-up') : t('go-to-panel')}
                </Link>
              </div>
              {/* <div className='openMobSide'>
                <FontAwesomeIcon icon={faBars} />
              </div> */}
            </div>
          </div>
        </div>
      </header>
      <section className='section hero-section' id='section1'>
        <div className='container'>
          <div className='row'>
            <div className='image center'>
              <img alt='mobile app' src={IMAGE} />
            </div>
            <div className='content'>
              <h1>{t('hero-header')}</h1>
              <h3>{t('hero-content')}</h3>
              <Link className='btn' to='home'>
                {!isAuth ? t('sign-up') : t('go-to-panel')}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className='services_row' id='section2'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12 center titleRow' data-aos='fade-in'>
              <h2> {t('services-title')}</h2>
              <h4> {t('services-sub')}</h4>
            </div>

            <div className='row'>
              <div className='col-md-4 col-sm-12'>
                <div className='feature-box' data-aos='fade-in' data-aos-delay='100'>
                  <div className='feature-box-icon'>
                    <img a src={ICO14} alt='' />
                  </div>
                  <div className='feature-box-info'>
                    <h4 className='title'>{t('feature-box-title-1')}</h4>
                    <p className='txt'>{t('feature-box-sub-1')}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-4 col-sm-12'>
                <div className='feature-box' data-aos='fade-in' data-aos-delay='400'>
                  <div className='feature-box-icon'>
                    <img src={ICO07} alt='' />
                  </div>
                  <div className='feature-box-info'>
                    <h4 className='title'>{t('feature-box-title-2')} </h4>
                    <p className='txt'>{t('feature-box-sub-2')}</p>
                  </div>
                </div>
              </div>
              <div className='col-md-4 col-sm-12'>
                <div className='feature-box' data-aos='fade-in' data-aos-delay='600'>
                  <div className='feature-box-icon'>
                    <img src={ICO04} alt='' />
                  </div>
                  <div className='feature-box-info'>
                    <h4 className='title'>{t('feature-box-title-3')}</h4>
                    <p className='txt'>
                      <p className='txt'>{t('feature-box-sub-3')}</p>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='section_01 parallax'>
        <h2 className='title'>{t('parallax-title')}</h2>
        <div className='txt'>{t('parallax-sub')}</div>
      </section>

      <section className='section_spec' id='section3'>
        <div className='center titleRow' data-aos='fade-down'>
          <h2>{t('facilities-title')}</h2>
          <h4> {t('facilities-sub')}</h4>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='col-12 spec'>
              <div className='spec__item'>
                <img alt='icon' src={ICO11} />
                <h4> {t('facilities-box-11')}</h4>
              </div>
              <div className='spec__item'>
                <img alt='icon' src={ICO04} />
                <h4> {t('facilities-box-4')}</h4>
              </div>
              <div className='spec__item'>
                <img alt='icon' src={ICO12} />
                <h4> {t('facilities-box-12')}</h4>
              </div>
              <div className='spec__item'>
                <img alt='icon' src={ICO10} />
                <h4> {t('facilities-box-10')}</h4>
              </div>
              <div className='spec__item'>
                <img alt='icon' src={ICO01} />
                <h4> {t('facilities-box-1')}</h4>
              </div>
              <div className='spec__item'>
                <img alt='icon' src={ICO08} />
                <h4> {t('facilities-box-8')}</h4>
              </div>
              <div className='spec__item'>
                <img alt='icon' src={ICO06} />
                <h4> {t('facilities-box-6')}</h4>
              </div>
              <div className='spec__item'>
                <img alt='icon' src={ICO13} />
                <h4> {t('facilities-box-13')}</h4>
              </div>
              <div className='spec__item'>
                <img alt='icon' src={ICO05} />
                <h4> {t('facilities-box-5')}</h4>
              </div>
              <div className='spec__item'>
                <img alt='icon' src={ICO02} />
                <h4> {t('facilities-box-2')}</h4>
              </div>
              <div className='spec__item'>
                <img alt='icon' src={ICO03} />
                <h4> {t('facilities-box-3')}</h4>
              </div>
              <div className='spec__item'>
                <img alt='icon' src={ICO09} />
                <h4> {t('facilities-box-9')}</h4>
              </div>
              <div className='spec__item'>
                <img alt='icon' src={ICO14} />
                <h4> {t('facilities-box-14')}</h4>
              </div>
              <div className='spec__item'>
                <img alt='icon' src={ICO07} />
                <h4> {t('facilities-box-7')}</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='section_blog' id='section4'>
        <div className='titleRow center'>
          <h2>{t('blog')}</h2>
        </div>

        <div className='container'>
          <div className='row'>
            {posts?.map((item, index) => {
              return (
                <React.Fragment key={item.id + index}>
                  <div className='col-lg-4'>
                    <div className='post'>
                      <figure className='post__img'>
                        <img alt='' className='img-fluid' src={item.image} />
                      </figure>
                      <div className='post__body'>
                        <h3>
                          <a href={item.link}>{item.title}</a>
                        </h3>
                        <p>{item.short_description}</p>
                        <a className='btn btn-border' href={item.link}>
                          {t('read-more')}
                        </a>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </section>

      <footer id='footer'>
        <div className='container'>
          <div className='row'>
            <div className='col contact_row'>
              <div className='add'>{t('address')}</div>
              <div className='phone'>
                <span className='pl-1'>{t('tel')} </span> <span> {t('tel-num')}</span>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col copyright_row'>
              <p>{t('copy right')}</p>

              <ul className='social-icons'>
                <li className='social-icons-github'>
                  <a href='/'>
                    <FontAwesomeIcon icon={faGithub} />
                  </a>
                </li>
                <li className='social-icons-instagram'>
                  <a href='/' target='_blank' title='instagram'>
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                </li>
                <li className='social-icons-facebook'>
                  <a href='/' target='_blank' title='Facebook'>
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </li>
                <li className='social-icons-twitter'>
                  <a href='/' target='_blank' title='Twitter'>
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Landing

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import FacilitiesSubmit from '../facilitiesSubmit'
import ResidentHeader from '../residentHeader'

import './styles.scss'
import ResidentFacilityList from '../residentFacilityList'

const ResidentFacilities = () => {

  const {t} = useTranslation()

  const [tab, setTab] = useState(0)

  return (
    <>
      <ResidentHeader />
      <div className='residentFacilitiesPage'>
        <div className='residentFacilitiesPage__header'>
          <span>{t('shared-facilities')}</span>
        </div>
        <div className='residentFacilitiesPage__body'>
          <div className='residentFacilitiesPage__tabs'>
            <button
              className={`residentFacilitiesPage__tab ${tab === 0 && 'residentFacilitiesPage__tab--active'}`}
              type='button'
              onClick={() => setTab(0)}
            >
              {t('submit-use-request')}
            </button>
            <button
              className={`residentFacilitiesPage__tab ${tab === 1 && 'residentFacilitiesPage__tab--active'}`}
              type='button'
              onClick={() => setTab(1)}
            >
              {t('requests-list')}
            </button>
          </div>
          {tab === 0 ? <FacilitiesSubmit /> : <ResidentFacilityList />}
        </div>
        <div className='residentFacilitiesPage__advertise'>
          <span>{t('advertising')}</span>
          <span>Ad</span>
        </div>
      </div>
    </>
  )
}

export default ResidentFacilities

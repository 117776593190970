/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faFilter } from '@fortawesome/free-solid-svg-icons'

import Button from '../../../components/button/Button'
import Modal from '../../../components/Modal/ModalPage'
import ManagerHeader from '../../../components/managerHeader'
import ResidentHeader from '../../../components/residentHeader'
import Input from '../../../components/input/Input'
import * as SharedActions from '../../redux/actions/SharedActions'

import moment from 'moment-jalaali'

import DatePicker from 'react-modern-calendar-datepicker'
import Service from '../../Api/Service'

import { useModal } from '../../hooks/useModal'

import { useTranslation } from 'react-i18next'

import './RegisteredPlatesList.scss'

const mockUnits = [
  {
    id: 1,
    unit_No: 1,
    date: '11/17/2020',
    plate_No: 'IR11-AB-666-c',
  },
  {
    id: 2,
    unit_No: 2,
    date: '11/17/2020',
    plate_No: 'IR11-cB-666-d',
  },
  {
    id: 3,
    unit_No: 3,
    date: '11/6/2020',
    plate_No: 'IR11-FG-666-E',
  },
  {
    id: 4,
    unit_No: 3,
    date: '11/6/2020',
    plate_No: 'IR11-FG-666-E',
  },
  {
    id: 5,
    unit_No: 3,
    date: '11/6/2020',
    plate_No: 'IR11-FG-666-E',
  },
  {
    id: 6,
    unit_No: 3,
    date: '11/6/2020',
    plate_No: 'IR11-FG-666-E',
  },
  {
    id: 7,
    unit_No: 3,
    date: '11/6/2020',
    plate_No: 'IR11-FG-666-E',
  },
  {
    id: 8,
    unit_No: 3,
    date: '11/6/2020',
    plate_No: 'IR11-FG-666-E',
  },
]

const TableData = ({ units }) => {
  return units.map((unit, index) => {
    const { id, unit_No, date, plate_No } = unit
    return (
      <tr key={id}>
        <td> {id} </td>
        <td> {unit_No} </td>
        <td> {date} </td>
        <td> {plate_No} </td>
      </tr>
    )
  })
}

const TableHeader = ({ units }) => {
  let header = Object.keys(units[0])
  return header.map((key, index) => {
    return <th key={index}> {key.toUpperCase()} </th>
  })
}

function RegisteredPlatesList({
  history,
  match: {
    params: { lang },
  },
}) {
  const residenceId = useSelector((state) => state.shared.residence_id)
  const userType = useSelector((state) => state.shared.userType) || 'manager'

  useEffect(() => {
    callManagerRequest()
  }, [])

  // Redux actions
  const dispatch = useDispatch()

  const callManagerRequest = async () => {
    const response = await Service.GET_ALL_COSTS({ id: residenceId })
    setData(response?.data)

    console.log('get data')
  }

  const REGISTERD_PLATE_MODAL = 'REGISTERD_PLATE_MODAL'
  const toggleModal = useModal(REGISTERD_PLATE_MODAL)

  const [data, setData] = useState([])
  const [unitNo, setUnitNo] = useState([])
  const [date, setDate] = useState(null)
  const [plateNo, setPlateNo] = useState()

  const [validation, setValidation] = useState({ unitNo: true, date: true, plateNo: true })

  const [units, setUnits] = useState(mockUnits)

  const { t } = useTranslation()

  const makeNewEntryMdoal = () => {
    toggleModal(true)
  }
  const makeNewEntry = async () => {
    console.log(date)
    const datas = `${date?.year}/${date?.month}/${date?.day}`
    const finalDate = moment(datas, 'jYYYY/jM/jD')

    const bodyData = {
      unitNo,
      due_at: finalDate._i.slice(0, -3),
      plateNo,
    }

    const apiConf = { body: bodyData, id: residenceId }
    const inputsAreValid = unitNo && date && plateNo
    setValidation({ unit: !!unitNo, plate: !!plateNo, date: !!date })
    if (inputsAreValid) postRequest(apiConf)
  }
  const postRequest = async (apiConf) => {
    console.log('helllo')

    const response = await Service.POST_MAKE_COST(apiConf)
    if (response?.status === 200) {
      dispatch(
        SharedActions.setNotif({
          type: 'success',
          message: `${response?.message}`,
        })
      )
      toggleModal(false)
      callManagerRequest()
    } else
      dispatch(
        SharedActions.setNotif({
          type: 'danger',
          message: `${response?.message}`,
        })
      )
  }

  return (
    <div className='report'>
      {userType === 'manager' ? <ManagerHeader /> : <ResidentHeader />}
      <div className='report__header'>
        <span> {t('registered-plates')} </span> {userType === 'manager'}
        {userType === 'manager' && (
          <Button
            handler={makeNewEntryMdoal}
            height='30px'
            label={t('register_entry')}
            minWidth='160px'
            type='primary'
          />
        )}
      </div>
      <div className='report__container'>
        <div className='report__report-card'>
          <div className='report__body'>
            <table className='report__units'>
              <tbody>
                <tr>
                  <TableHeader units={units} />
                </tr>
                <TableData units={units} />
              </tbody>
            </table>
            <Modal name={REGISTERD_PLATE_MODAL} title={t('register_entry')} toggle={toggleModal}>
              <div className='report__modal'>
                <div>
                  <span>{t('date')}:</span>
                  <div className='report__date'>
                    <FontAwesomeIcon color='rgba(0, 0, 0, 0.25)' icon={faCalendarAlt} />
                    <DatePicker
                      shouldHighlightWeekends
                      inputPlaceholder={t('publish-date')}
                      locale='fa'
                      value={date}
                      onChange={setDate}
                    />
                  </div>
                </div>
                {!validation.date && <span className='validation__message'>{t('is-required')}</span>}

                <Input
                  handler={(e) => setUnitNo(e.target.value)}
                  label={t('unit-no')}
                  name='buildingName'
                  placeholder={t('unit-no')}
                  tag='input'
                  type='number'
                  value={unitNo}
                />
                {!validation.price && <span className='validation__message'>{t('is-required')}</span>}
                <Input
                  handler={(e) => setPlateNo(e.target.value)}
                  label={t('plate-no')}
                  name='buildingAddress'
                  placeholder={t('plate-no')}
                  tag='input'
                  type='text'
                  value={plateNo}
                />
                {!validation.des && <span className='validation__description'>{t('is-required')}</span>}
                <Button
                  handler={makeNewEntry}
                  height='35px'
                  label={t('register_entry')}
                  minWidth='130px'
                  type='primary'
                />
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RegisteredPlatesList

/* eslint-disable react/display-name */
import React from 'react'
// Layout Types
import { DefaultLayout, DashboardLayout /* , EmptyLayout */ } from '../layouts'

// Route containers
import Otp from './pages/otp/Otp'
import Home from './pages/home/Home'
import Login from './pages/login/Login'
import SignUp from './pages/signUp/SignUp'
import Landing from './pages/landing/Landing'
// import UserType from './pages/signUp/userType'
import Facilities from './pages/facilities'
import Advertising from './pages/advertising'
import UnitsList from './pages/addUnits/UnitsList'
import Dashboard from './pages/dashboard/Dashboard'
import EditProfile from './pages/dashboard/EditProfile'
import AddBuilding from './pages/addBuilding/AddBuilding'
import Anouncemenets from './pages/anouncemenets/Anouncemenets'
import RegistrationCode from './pages/registrationCode/RegistrationCode'
// import AddBuildingNumber from './pages/registrationCode/AddBuildingNumber'
import SwitchBetweenBuildings from './pages/switchBetweenBuildings/SwitchBetweenBuildings'
import BuildingCosts from './pages/buildingCosts/BuildingCosts'
// import FinancialSaving from './pages/financial-2/FinancialSaving'
import FinancialSaving from './pages/financialSaving'
import AfterPayment from './pages/afterPayment'
import AddCamera from './pages/addCamera/AddCamera'
import AddVehicle from './pages/addVehicle/AddVehicle'
import AddGuest from './pages/addGuest/AddGuest'
import IncomeReport from './pages/incomeReport/IncomeReport'
import RegisteredPlatesList from './pages/registeredPlatesList/RegisteredPlatesList'
import UnitsParkingReport from './pages/unitsParkingReport/UnitsParkingReport'

export default [
    // Authorization
    {
        path: '/:lang?/home',
        layout: DefaultLayout,
        exact: true,
        component: Home,
        guest: true,
    },
    {
        path: '/:lang?/login',
        layout: DefaultLayout,
        exact: true,
        component: Login,
        guest: true,
    },
    {
        path: '/:lang?/signup',
        layout: DefaultLayout,
        exact: true,
        component: SignUp,
        guest: true,
    },
    {
        path: '/:lang?/registration-code',
        layout: DefaultLayout,
        exact: true,
        component: RegistrationCode,
        protected: true,
    },
    // {
    //   path: '/:lang?/registration-code-number',
    //   layout: DefaultLayout,
    //   exact: true,
    //   component: AddBuildingNumber,
    //   protected: true,
    // },
    {
        path: '/:lang?/otp-login',
        layout: DefaultLayout,
        exact: true,
        component: Otp,
        guest: true,
    },
    {
        path: '/:lang?/add-building',
        layout: DefaultLayout,
        exact: true,
        component: AddBuilding,
        protected: true,
    },
    {
        path: '/:lang?/dashboard',
        layout: DefaultLayout,
        exact: true,
        component: Dashboard,
        protected: true,
    },
    {
        path: '/:lang?/edit-profile',
        layout: DefaultLayout,
        exact: true,
        component: EditProfile,
        protected: true,
    },
    {
        path: '/:lang?/switch-building',
        layout: DefaultLayout,
        exact: true,
        component: SwitchBetweenBuildings,
        protected: true,
    },
    {
        path: '/:lang?/announcements',
        layout: DashboardLayout,
        exact: true,
        component: Anouncemenets,
        protected: true,
    },
    {
        path: '/:lang?/units-list',
        layout: DefaultLayout,
        exact: true,
        component: UnitsList,
        protected: true,
    },
    // {
    //   path: '/:lang?/user-type',
    //   layout: DefaultLayout,
    //   exact: true,
    //   component: UserType,
    //   protected: true,
    // },

    // {
    //   path: '/:lang?/manager',
    //   layout: DefaultLayout,
    //   exact: true,
    //   component: Manager,
    // },
    // {
    //   path: "/:lang?/register",
    //   layout: EmptyLayout,
    //   exact: true,
    //   component: Register
    // },

    // Pages
    {
        path: '/:lang?/landing',
        layout: DefaultLayout,
        exact: true,
        component: Landing,
    },
    {
        path: '/:lang?/:role-facilities',
        layout: DashboardLayout,
        exact: true,
        protected: true,
        component: Facilities,
    },
    {
        path: '/:lang?/building-costs',
        layout: DashboardLayout,
        exact: true,
        protected: true,
        component: BuildingCosts,
    },
    {
        path: '/:lang?/financial-saving',
        layout: DashboardLayout,
        exact: true,
        protected: true,
        component: FinancialSaving,
    },
    {
        path: '/:lang?/(success|failed)-payment',
        layout: DashboardLayout,
        exact: true,
        protected: true,
        component: AfterPayment,
    },
    {
        path: '/:lang?/:role-advertising',
        layout: DashboardLayout,
        exact: true,
        protected: true,
        component: Advertising,
    },
    // {
    //   path: '/:lang?/financial-saving',
    //   layout: DashboardLayout,
    //   exact: true,
    //   protected: true,
    //   component: FinancialSaving,
    // },

    {
        path: '/:lang?/add-camera',
        layout: DefaultLayout,
        exact: true,
        component: AddCamera,
        protected: true,
    },
    {
        path: '/:Lang?/add-vehicle',
        layout: DefaultLayout,
        exact: true,
        component: AddVehicle,
        protected: true,
    },
    {
        path: '/:lang?/add-guest',
        layout: DefaultLayout,
        exact: true,
        component: AddGuest,
        protected: true,
    },
    {
        path: '/:lang?/income-report',
        layout: DefaultLayout,
        exact: true,
        component: IncomeReport,
        protected: true,
    },
    {
        path: '/:lang?/plates-list',
        layout: DefaultLayout,
        exact: true,
        component: RegisteredPlatesList,
        protected: true,
    },
    {
        path: '/:lang?/units-parking',
        layout: DefaultLayout,
        exact: true,
        component: UnitsParkingReport,
        protected: true,
    },
    {
        path: '/:lang?/',
        exact: true,
        layout: DefaultLayout,
        component: Landing,
    },
    {
        path: '*',
        layout: ({ children }) => children,
        component: () => < h1 style = {
            { textAlign: 'center', marginTop: '40%' } } > 404 | Not Found! < /h1>,
    },
]
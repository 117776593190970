import React, { useState } from 'react'
import {useTranslation} from 'react-i18next'

import { faCloudUploadAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import DatePicker from 'react-modern-calendar-datepicker'

import Modal from '../Modal/ModalPage'
import Input from '../input/Input'
import { useModal } from '../../js/hooks/useModal'

import './styles.scss'

const TYPES = {
  LOCAL: 'محلی',
  GLOBAL: 'عمومی',
}
const ads = [
  {
    type: 'LOCAL',
    body: 'این تبلیغات می توانند در تابلوی اعلانات و صفحات اعضای ساختمان نمایش داده شوند.',
  },
  {
    type: 'GLOBAL',
    body: 'این تبلیغات می توانند در تابلوی اعلانات و صفحات اعضای ساختمان نمایش داده شوند.',
  },
]

const SubmitAd = () => {
  const modalName = 'submit-ads'
  const toggleModal = useModal(modalName)

  const [type, setType] = useState(TYPES.LOCAL)
  const [agreed, setAgreed] = useState(false)
  const [date, setDate] = useState(null)

  const onChangeImage = () => {}
  const onChangeURL = () => {}
  const onSubmit = () => {
    toggleModal(false)
  }
  const onChangeTitle = () => {}
  const onChangeDuration = () => {}
  const onClickBox = (type) => {
    setType(type)
    toggleModal(true)
  }
  const {t} = useTranslation()
  return (
    <div className='submitAd'>
      <div className='submitAd__title'>{t('To order ads, select the display area and order your ad')}</div>
      <div className='submitAd__row'>
        {ads.map(({ type, body }) => (
          <div className='submitAd__box' key={type} onClick={() => onClickBox(type)}>
            <div className='submitAd__boxTitle'>{`تبلیغات ${TYPES[type]}`}</div>
            <div className='submitAd__boxBtn'>{t('advertising-request')}</div>
            <div className='submitAd__boxBody'>{body}</div>
          </div>
        ))}
      </div>
      <Modal name={modalName} title={`سفارش تبلیغات ${TYPES[type]}`} toggle={toggleModal}>
        <div className='submitAd__formRow'>
          <span>
            <span style={{ color: 'red' }}>*</span>
            <span>{t('advertising-title')}:</span>
          </span>
          <Input handler={onChangeTitle} name='title' placeholder={t('campaign-name')} />
        </div>
        <div className='submitAd__formRow'>
          <span>
            <span style={{ color: 'red' }}>*</span>
            <span>{t('start-from')}:</span>
          </span>
          <div className='submitAd__date'>
            <FontAwesomeIcon color='rgba(0, 0, 0, 0.25)' icon={faCalendarAlt} />
            <DatePicker shouldHighlightWeekends inputPlaceholder={t('date')} locale='fa' value={date} onChange={setDate} />
          </div>
        </div>
        <div className='submitAd__formRow'>
          <span>
            <span style={{ color: 'red' }}>*</span>
            <span>{t('for')}:</span>
          </span>
          <Input handler={onChangeDuration} name='duration' placeholder='۲ ماه' />
        </div>
        <div className='submitAd__divider' />
        <div className='submitAd__image'>
          <div className='submitAd__imageTitle'>{t('Ad image must be 200 x 600 pixels in JPG format')}</div>
        </div>
        <div className='submitAd__formRow browse'>
          <span>
            <span style={{ color: 'red' }}>*</span>
            <span>{t('image-upload')}:</span>
          </span>
          <div>
            <label>
              <FontAwesomeIcon color='#ffa70b' icon={faCloudUploadAlt} />
              <span>{t('file-upload')}</span>
            </label>
            <input name='duration' placeholder='URL' type='file' onChange={onChangeImage} />
          </div>
        </div>
        <div className='submitAd__formRow'>
          <span>
            <span style={{ color: 'red' }}>*</span>
            <span>{t('ad-link')}:</span>
          </span>
          <Input handler={onChangeURL} name='duration' placeholder='URL' />
        </div>
        <div className='submitAd__legal'>
          <div>{t('facility-rules')}</div>
          <div>
            لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است. چاپگرها و متون
            بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع
            با هدف بهبود ابزارهای کاربردی می باشد.
          </div>
        </div>
        <label className='submitAd__sign'>
          <input checked={agreed} type='checkbox' onChange={() => setAgreed(!agreed)} />
          <span>{t('accept-rules')}</span>
        </label>
        <div className='submitAd__button' onClick={onSubmit}>
        {t('send-request')}
        </div>
      </Modal>
    </div>
  )
}

export default SubmitAd

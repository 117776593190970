import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Header from '../../../components/header/Header'
import Input from '../../../components/input/Input'
import Button from '../../../components/button/Button'

import './AddVehicle.scss'

function AddVehicle({
  history,
  match: {
    params: { lang },
  },
}) {
  const { t } = useTranslation()
  const [validation, setValidation] = useState({
    unitNo: true,
    parkingNo: true,
    vehicleType: true,
    plateNo: true,
    cost: true,
    licenseType: true,
  })

  const [vehicleInformation, setVehicleInformation] = useState({
    unitNo: '',
    parkingNo: '',
    vehicleType: '',
    plateNo: '',
    cost: '',
    licenseType: '',
    errormessage: '',
  })
  const { unitNo, parkingNo, vehicleType, plateNo, cost, licenseType } = vehicleInformation
  const vehicleInformationHandler = (e, name) => {
    let newInformation
    newInformation = { ...vehicleInformation, [name]: e.target.value }
    setVehicleInformation(newInformation)
  }

  const makeNewVehicle = async () => {
    const bodyData = {
      unitNo,
      parkingNo,
      vehicleType,
      plateNo,
      cost,
      licenseType,
    }

    const apiConf = { body: bodyData }
    const inputsAreValid = unitNo && parkingNo && vehicleType && plateNo && cost && licenseType
    setValidation({
      unit: !!unitNo,
      parking: !!parkingNo,
      type: !!vehicleType,
      plate: !!plateNo,
      cost_day: !!cost,
      license: licenseType,
    })
    if (inputsAreValid) postRequest(apiConf)
  }
  const postRequest = async (apiConf) => {
    console.log('data send successfull')
  }

  return (
    <div className='register'>
      <Header />
      <div className='register__container'>
        <div className='register__register-card'>
          <div className='register__body'>
            <div className='register__section'>
              <Input
                handler={(e, name) => vehicleInformationHandler(e, name)}
                label={t('unit-no')}
                name='unitNo'
                placeholder={t('unit-no')}
                type='text'
                tag='input'
                value={unitNo}
              />
            </div>
            {!validation.unit && <span className='validation__message'>{t('is-required')}</span>}

            <div className='register__section'>
              <Input
                handler={(e, name) => vehicleInformationHandler(e, name)}
                label={t('parking-no')}
                name='parkingNo'
                placeholder={t('parking-no')}
                type='text'
                tag='input'
                value={parkingNo}
              />
            </div>
            {!validation.parking && <span className='validation__message'>{t('is-required')}</span>}

            <div className='register__section'>
              <Input
                handler={(e, name) => vehicleInformationHandler(e, name)}
                label={t('vehicle-type')}
                name='vehicleType'
                placeholder={t('vehicle-type')}
                type='text'
                tag='input'
                value={vehicleType}
              />
            </div>
            {!validation.type && <span className='validation__message'>{t('is-required')}</span>}

            <div className='register__section'>
              <Input
                handler={(e, name) => vehicleInformationHandler(e, name)}
                label={t('plate-no')}
                name='plateNo'
                placeholder={t('plate-no')}
                type='text'
                tag='input'
                value={plateNo}
              />
            </div>
            {!validation.plate && <span className='validation__message'>{t('is-required')}</span>}

            <div className='register__section'>
              <Input
                handler={(e, name) => vehicleInformationHandler(e, name)}
                label={t('cost-per-day')}
                name='cost'
                placeholder={t('cost-per-day')}
                type='text'
                tag='input'
                value={cost}
              />
            </div>
            {!validation.cost_day && <span className='validation__message'>{t('is-required')}</span>}
            <div className='register__section'>
              <Input
                handler={(e, name) => vehicleInformationHandler(e, name)}
                label={t('license-type')}
                name='licenseType'
                placeholder={t('license-type')}
                type='text'
                tag='input'
                value={licenseType}
              />
            </div>
            {!validation.license && <span className='validation__message'>{t('is-required')}</span>}

            <div className='register__seperator'> </div>
            <div className='register__section'>
              <Button
                handler={() => history.push('/dashboard')}
                fontSize='13px'
                height='35px'
                label={t('dashboard')}
                type='bordered'
                width='128px'
              />
              <Button handler={makeNewVehicle} fontSize='13px' height='35px' label={t('save')} width='128px' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AddVehicle

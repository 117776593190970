import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import SubmitAd from '../submitAd'
import ResidentHeader from '../residentHeader'

import './styles.scss'

const ResidentAdvertising = () => {
  const { t } = useTranslation()

  const [tab, setTab] = useState(0)

  return (
    <>
      <ResidentHeader />
      <div className='advertisingPage'>
        <div className='advertisingPage__header'>
          <span>{t('advertising')}</span>
        </div>
        <div className='advertisingPage__body'>
          <div className='advertisingPage__tabs'>
            <button
              className={`advertisingPage__tab ${tab === 0 && 'advertisingPage__tab--active'}`}
              type='button'
              onClick={() => setTab(0)}
            >
              {t('advertising-request')}
            </button>
            <button
              className={`advertisingPage__tab ${tab === 1 && 'advertisingPage__tab--active'}`}
              type='button'
              onClick={() => setTab(1)}
            >
              {t('advertising-list')}
            </button>
          </div>
          {tab === 0 ? <SubmitAd /> : null}
        </div>
        <div className='advertisingPage__advertise'>
          <span>{t('advertising')}</span>
          <span>Ad</span>
        </div>
      </div>
    </>
  )
}

export default ResidentAdvertising

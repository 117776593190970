import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/es/integration/react'
// import Cookies from 'js-cookie'

import App from './App'
import './i18n'

// import * as serviceWorker from './serviceWorker'
import configreStore from './js/redux/configureStore'

const { store, persistor } = configreStore()

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

if (navigator.serviceWorker) {
  navigator.serviceWorker
    .register('./sw.js')
    .then((registration) => {
      console.log('ServiceWorker registration successful with scope:', registration.scope)
    })
    .catch((error) => {
      console.log('ServiceWorker registration failed:', error)
    })
}

import React from 'react'

import './registrationCode.scss'

import { useTranslation } from 'react-i18next'
import Header from '../../../components/header/Header'
import Button from '../../../components/button/Button'

export default function RegistrationCode({
  history,
  match: {
    params: { lang },
  },
}) {
  const { t } = useTranslation()
  const prefix = lang ? `/${lang}` : ''

  return (
    <div className='registration-code'>
      <Header />
      <div className='registration-code__main'>
        <span> {t('you-are-not-a-member-of-any-building')} </span>
        <Button
          handler={() => history.push(`${prefix}/add-building`)}
          height='35px'
          label={t('add')}
          minWidth='50px'
          type='primary'
          width='155px'
        />
      </div>
    </div>
  )
}

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'

import './Input.scss'

export default function Input(props) {
  const { name, handler, type, label, tag, placeholder, oneTime, value } = props

  return (
    <div>
      {!!label && <label htmlFor={name}>{label}:</label>}
      {tag === 'textArea' ? (
        <textarea id='' name='' placeholder={placeholder} rows='4' value={value} onChange={(e) => handler(e, name)} />
      ) : (
        <input
          auto-complete={oneTime ? 'one-time-code' : null}
          className='form-control'
          name={name}
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={(e) => handler(e, name)}
        />
      )}
    </div>
  )
}

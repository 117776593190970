import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Header from '../../../components/header/Header'
import Input from '../../../components/input/Input'
import Button from '../../../components/button/Button'

import './AddCamera.scss'

function AddCamera({
  history,
  match: {
    params: { lang },
  },
}) {
  const { t } = useTranslation()
  const [validation, setValidation] = useState({ cameraName: true, cameraLocation: true, cameraType: true })

  const [cameraInformation, setCameraInformation] = useState({
    cameraName: '',
    cameraLocation: '',
    cameraType: '',
  })

  const { cameraName, cameraLocation, cameraType } = cameraInformation

  const cameraInformationHandler = (e, name) => {
    let newInformation
    newInformation = { ...cameraInformation, [name]: e.target.value }
    setCameraInformation(newInformation)
  }

  const makeNewCamera = async () => {
    const bodyData = {
      cameraName,
      cameraLocation,
      cameraType,
    }

    const apiConf = { body: bodyData }
    const inputsAreValid = cameraName && cameraType && cameraLocation
    setValidation({ name: !!cameraName, type: !!cameraType, location: !!cameraLocation })
    if (inputsAreValid) postRequest(apiConf)
  }
  const postRequest = async (apiConf) => {
    console.log('data send successfull')
  }

  return (
    <div className='register'>
      <Header />
      <div className='register__container'>
        <div className='register__register-card'>
          <div className='register__body'>
            <div className='register__section'>
              <Input
                handler={(e, name) => cameraInformationHandler(e, name)}
                label={t('camera-name')}
                name='cameraName'
                placeholder={t('camera-name')}
                type='text'
                value={cameraName}
              />
            </div>
            {!validation.name && <span className='validation__message'>{t('is-required')}</span>}

            <div className='register__section'>
              <Input
                handler={(e, name) => cameraInformationHandler(e, name)}
                label={t('camera-location')}
                name='cameraLocation'
                placeholder={t('camera-location')}
                type='text'
                value={cameraLocation}
              />
            </div>
            {!validation.location && <span className='validation__message'>{t('is-required')}</span>}

            <div className='register__section'>
              <Input
                handler={(e, name) => cameraInformationHandler(e, name)}
                label={t('camera-type')}
                name='cameraType'
                placeholder={t('camera-type')}
                type='text'
                value={cameraType}
              />
            </div>
            {!validation.type && <span className='validation__message'>{t('is-required')}</span>}

            <div className='register__seperator'> </div>
            <div className='register__section'>
              <Button
                handler={() => history.push('/dashboard')}
                fontSize='13px'
                height='35px'
                label={t('dashboard')}
                type='bordered'
                width='128px'
              />
              <Button handler={makeNewCamera} fontSize='13px' height='35px' label={t('save')} width='128px' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AddCamera

import React, { useEffect } from 'react'

import { isIOS } from 'react-device-detect'
import Cookies from 'js-cookie'

import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Modal from '../Modal'
import { useModal } from '../../js/hooks/useModal'

import LOGO from '../../assets/img/logo.png'
import MENU from '../../assets/img/addtohome/menu.svg'
import ADD from '../../assets/img/addtohome/add.svg'
import ADD_TO_HOME from '../../assets/img/addtohome/addtohome.svg'

import './styles.scss'

const AddToHomeScreen = () => {
  const homeScreenModal = 'add-to-home'
  const toggle = useModal(homeScreenModal)

  const isIPhoneActive = Cookies.get('iPhoneActivation')

  useEffect(() => {
    if (isIOS && !window.navigator.standalone && !isIPhoneActive) {
      toggle(true)
      Cookies.set('iPhoneActivation', 'true', { expires: 1 })
    }
  }, [])

  const onClickButton = () => toggle(false)

  return (
    <Modal name={homeScreenModal} title='' toggle={toggle}>
      <div className='addtoHomeClose'>
        <button className='addtoHomeClose__button' type='button' onClick={() => toggle(false)}>
          <FontAwesomeIcon color='#D9D9D9' icon={faTimes} size='2x' />
        </button>
      </div>
      <section className='containerAddToHome'>
        <img alt='logo' className='containerAddToHome__logo' src={LOGO} />
        <span className='containerAddToHome__title'>نسخه وب ‌اپلیکیشن (PWA) آی‌بیلدینگ را به صفحه اصلی اضافه کنید</span>
        <span className='containerAddToHome__description'>
          با این کار، می‌توانید برای همیشه و بدون نیاز به به‌روز‌رسانی از خدمات آی‌بیلدینگ استفاده کنید
        </span>
        <div className='containerAddToHome__row'>
          <span>
            ۱. روی دکمه
            <span>Share</span>
            در نوار پایین کلیک کنید.
          </span>
          <img alt='menu' src={MENU} />
        </div>
        <div className='containerAddToHome__row'>
          <span>
            ۲. گزینه
            <span>Add to Home Screen</span>
            را فعال کنید.
          </span>
          <img alt='add' src={ADD_TO_HOME} />
        </div>
        <div className='containerAddToHome__row'>
          <span>
            ۳. در قسمت بالا روی
            <span>Add</span>
            کلیک کنید.
          </span>
          <img alt='add' src={ADD} />
        </div>

        <button type='button' onClick={onClickButton}>
          متوجه شدم!
        </button>
      </section>
    </Modal>
  )
}

export default AddToHomeScreen

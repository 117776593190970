import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { Link } from 'react-router-dom'

import './styles.scss'
import FacilitiesSubmit from '../facilitiesSubmit'
import FacilitiesList from '../facilitiesList'
import ManagerHeader from '../managerHeader'

const Facilities = () => {
  const [tab, setTab] = useState(1)
  const { t } = useTranslation()
  return (
    <>
      <ManagerHeader />
      <div className='managerFacilitiesPage'>
        {/* <div className='managerFacilitiesPage__header'>
          <span>امکانات اشتراکی</span>
          <Link to='/facilities/edit'>ویرایش امکانات</Link>
        </div> */}
        <div className='managerFacilitiesPage__body'>
          <div className='managerFacilitiesPage__tabs'>
            <button
              className={`managerFacilitiesPage__tab ${tab === 0 && 'managerFacilitiesPage__tab--active'}`}
              type='button'
            >
              {t('submit-use-request')}
            </button>
            <button
              className={`managerFacilitiesPage__tab ${tab === 1 && 'managerFacilitiesPage__tab--active'}`}
              type='button'
              onClick={() => setTab(1)}
            >
              {t('requests-list')}
            </button>
          </div>
          {tab === 0 ? <FacilitiesSubmit /> : <FacilitiesList />}
        </div>
      </div>
    </>
  )
}

export default Facilities

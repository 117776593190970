import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import moment from 'moment-jalaali'

import { setNotif } from '../../js/redux/actions/SharedActions'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import 'react-modern-calendar-datepicker/lib/DatePicker.css'
import DatePicker from 'react-modern-calendar-datepicker'

import Modal from '../Modal/ModalPage'
import Input from '../input/Input'
import NumberInput from '../input/NumberInput'
import { useModal } from '../../js/hooks/useModal'

import Service from '../../js/Api/Service'

import './styles.scss'
import { setAllFacilities, getFacilityTerms } from '../../js/redux/actions/FacilitiesActions'

// const FACILITIES = {
//   pool: 'استخر ۵۲ متری',
//   gym: 'سالن ورزش',
//   conference: 'سالن کنفرانس',
// }

const FacilitiesSubmit = () => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const shared = useSelector((state) => state.shared)

  const facilities = useSelector((state) => state.facilities.all)
  const terms = useSelector((state) => state.facilities.terms)

  const requestModalName = 'request-facilities-modal'
  const toggleModal = useModal(requestModalName)

  const [facility, setFacility] = useState('')
  const [fromTime, setFromTime] = useState('')
  const [toTime, setToTime] = useState('')
  const [description, setDescription] = useState('')
  const [agreed, setAgreed] = useState(false)

  const [toDate, setToDate] = useState(null)
  const [fromDate, setFromDate] = useState(null)

  const [zIndex, setZIndex] = useState({ 0: 10, 1: 10 })

  const onClickFacility = (facility) => {
    toggleModal(true)
    setFacility(facility)
  }

  async function postNewRequest(body) {
    const response = await Service.POST_REQUEST_FACILITY({ body })
    toggleModal(false)
    if (response.status === 200) {
      dispatch(setNotif({ type: 'success', message: `${response.message}` }))
    }
  }

  const getTimeString = (date, time) => {
    const datas = `${date?.year}/${date?.month}/${date?.day}-${time}`
    console.log(22222, datas)
    return moment(datas, 'jYYYY/jM/jD-HH').format('YYYY-M-D HH:mm:ss')
  }

  const onSubmit = () => {
    if (!agreed) return
    const body = {
      residence_id: shared.residence_id,
      facility_id: facility.id,
      request_time_from: getTimeString(fromDate, fromTime),
      request_time_to: getTimeString(toDate, toTime),
      description,
    }
    postNewRequest(body)
  }

  const onChangeTime = (dir, name) => {
    const handler = name.includes('from') ? setFromTime : setToTime
    const state = name.includes('from') ? fromTime : toTime
    switch (dir) {
      case 'inc':
        if (state > 23) return
        handler((state || 0) + 1)
        break

      case 'dec':
        if (state < 2) return
        handler((state || 1) - 1)
        break

      default:
        break
    }
  }

  const onClickDate = (i) => {
    const j = 1 - i
    setZIndex({ [i]: 11, [j]: 10 })
  }

  const renderFromInput = ({ ref }) => {
    return (
      <>
        <input
          readOnly
          className='DatePicker__input -rtl'
          placeholder={t('date')}
          ref={ref}
          value={fromDate ? `${fromDate.year}/${fromDate.month}/${fromDate.day}` : ''}
        />
        <FontAwesomeIcon color='rgba(0, 0, 0, 0.25)' icon={faCalendarAlt} />
      </>
    )
  }

  const renderToInput = ({ ref }) => {
    return (
      <>
        <input
          readOnly
          className='DatePicker__input -rtl'
          placeholder={t('date')}
          ref={ref}
          value={toDate ? `${toDate.year}/${toDate.month}/${toDate.day}` : ''}
        />
        <FontAwesomeIcon color='rgba(0, 0, 0, 0.25)' icon={faCalendarAlt} />
      </>
    )
  }

  useEffect(() => {
    async function fetchAllFacilities() {
      const response = await Service.GET_BUILDING_FACILITIES({ id: shared.residence_id })
      if (response.status === 200) dispatch(setAllFacilities(response.data))
    }
    dispatch(getFacilityTerms())
    if (!facilities.length) fetchAllFacilities()
  }, [])

  return (
    <div className='submit'>
      {facilities.map((info, index) => (
        <div className='submit__card' key={index}>
          <div>{info.title}</div>
          <div onClick={() => onClickFacility(info)}>{t('use-request')}</div>
        </div>
      ))}
      <Modal name={requestModalName} title={t('use-request')} toggle={toggleModal}>
        <div className='requestModal__subject'>{facility.title}</div>
        <div className='requestModal__row'>
          <div className='requestModal__label'>
            <span style={{ color: 'red' }}>*</span>
            <span>{t('from')}:</span>
          </div>
          <div className='requestModal__date' style={{ zIndex: zIndex[0] }} onClick={() => onClickDate(0)}>
            <DatePicker
              shouldHighlightWeekends
              locale='fa'
              renderInput={renderFromInput}
              value={fromDate}
              onChange={setFromDate}
            />
          </div>
          <div className='requestModal__label'>
            <span style={{ color: 'red' }}>*</span>
            <span>{t('time')}:</span>
          </div>
          <div className='requestModal__time'>
            <NumberInput required handleInputValue={onChangeTime} name='fromTime' placeholder='ساعت' value={fromTime} />
          </div>
        </div>
        <div className='requestModal__row'>
          <div className='requestModal__label'>
            <span style={{ color: 'red' }}>*</span>
            <span>{t('to')}:</span>
          </div>
          <div className='requestModal__date' style={{ zIndex: zIndex[1] }} onClick={() => onClickDate(1)}>
            <DatePicker
              shouldHighlightWeekends
              locale='fa'
              renderInput={renderToInput}
              value={toDate}
              onChange={setToDate}
            />
          </div>
          <div className='requestModal__label'>
            <span style={{ color: 'red' }}>*</span>
            <span>{t('time')}:</span>
          </div>
          <div className='requestModal__time'>
            <NumberInput
              required
              handleInputValue={onChangeTime}
              name='toTime'
              placeholder={t('time')}
              value={toTime}
            />
          </div>
        </div>
        <div className='requestModal__detail'>
          <Input handler={(e) => setDescription(e.target.value)} placeholder={t('description')} tag='textArea' />
        </div>
        <div className='requestModal__legal'>
          <div>{t('facility-rules')}</div>
          <div>{terms}</div>
        </div>
        <label className='requestModal__sign'>
          <input checked={agreed} type='checkbox' onChange={() => setAgreed(!agreed)} />
          <span>{t('accept-rules')}</span>
        </label>
        <div className='requestModal__button' onClick={onSubmit}>
          {t('send-request')}
        </div>
      </Modal>
    </div>
  )
}

export default FacilitiesSubmit

import React from 'react'

import './styles.scss'

const BuildingCostsCard = (props) => {
  const { title, payed, price, id } = props
  return (
    <div className='buildingCostsCard__container '>
      <div className='buildingCostsCard'>
        <div className='buildingCostsCard__title'>
          <span>
            {id}. {title}
          </span>
          <span className={`buildingCostsCard__title--${payed ? 'payed' : 'notpayed'}`}>
            {price}
            <span className='buildingCostsCard__title--unit'>تومان</span>
          </span>
        </div>
        <div className='buildingCostsCard__month'>شهریورماه</div>
      </div>
    </div>
  )
}

export default BuildingCostsCard

import React from 'react'
import { useTranslation } from 'react-i18next'
import { faTimesCircle, faCheckCircle, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import moment from 'moment-jalaali'

import Modal from '../Modal/ModalPage'

import { useModal } from '../../js/hooks/useModal'

import { persianPrice } from '../../js/utils'
import { persianTime, persianDate } from '../../js/utils/moment'

import './styles.scss'

const icons = {
  waiting_for_manager: { icon: faSpinner, color: '#FED330', size: 'lg' },
  waiting_for_payment: { icon: faSpinner, color: '#999999', size: 'lg' },
  disapproved: { icon: faTimesCircle, color: '#FC5C65', size: 'lg' },
  approved: { icon: faCheckCircle, color: '#26DE81', size: 'lg' },
}

const ResidentRequestCard = ({
  facility,
  status,
  request_time_from,
  request_time_to,
  description,
  price,
  reason_of_disapproval,
}) => {
  const residentDetailModalName = 'resident-details-modal'
  const toggleModal = useModal(residentDetailModalName)

  const fromDate = persianDate(request_time_from)
  const toDate = persianDate(request_time_to)
  const { t } = useTranslation()

  return (
    <div className={`residentFacilitiesCard ${status}`}>
      {fromDate === toDate ? (
        <div className='residentFacilitiesCard__facility'>
          <span>{facility.title}</span>
          <div className='residentFacilitiesCard__from'>
            <span>{persianDate(request_time_from)}</span>
          </div>
          <div className='residentFacilitiesCard__from'>
            <span>{t('time-from')}</span>
            <span>{persianTime(request_time_from)}</span>
            <span>{t('to')}</span>
            <span>{persianTime(request_time_to)}</span>
          </div>
        </div>
      ) : (
        <div className='residentFacilitiesCard__facility'>
          <span>{facility.title}</span>
          <div className='residentFacilitiesCard__from'>
            <span>{persianDate(request_time_from)}</span>
            <span>
              - {t('time')} {persianTime(request_time_from)}
            </span>
          </div>
          <div className='residentFacilitiesCard__from'>
            <span>{persianDate(request_time_to)}</span>
            <span>
              - {t('time')} {persianTime(request_time_to)}
            </span>
          </div>
        </div>
      )}
      <div className='residentFacilitiesCard__status'>
        <span className={`residentFacilitiesCard__facility--${status}`}>
          {status === 'waiting_for_manager' ? (
            <>
              {status !== 'waiting_for_payment' && (
                <div>
                  <FontAwesomeIcon {...icons[status]} />
                </div>
              )}
              <span>{t('waiting-for-manager')}</span>
            </>
          ) : status === 'waiting_for_payment' ? (
            <div>
              <div className='residentFacilitiesCard__payment'>
                <span>{persianPrice(price)}</span>
                <span>{t('toman')}</span>
              </div>
              <div className='residentFacilitiesCard__paymentBtn'>{t('payment')}</div>
            </div>
          ) : status === 'approved' ? (
            <>
              {status !== 'waiting_for_payment' && (
                <div>
                  <FontAwesomeIcon {...icons[status]} />
                </div>
              )}
              <div>{t('approved')}</div>
            </>
          ) : status === 'disapproved' ? (
            <>
              {status !== 'waiting_for_payment' && (
                <div>
                  <FontAwesomeIcon {...icons[status]} />
                </div>
              )}
              <span> {t('disapproved')}</span>
              <div className='residentFacilitiesCard__rejectedBtn' onClick={() => toggleModal(true)}>
                {t('cause-of-disapproving')}
              </div>
            </>
          ) : (
            ''
          )}
          <div className='residentFacilitiesCard__details'>{description}</div>
        </span>
      </div>
      <Modal name={residentDetailModalName} title={t('cause-of-disapproving')} toggle={toggleModal}>
        <div>{reason_of_disapproval}</div>
        <div className='residentFacilitiesCard__button' onClick={() => toggleModal(false)}>
          {t('close')}
        </div>
      </Modal>
    </div>
  )
}

export default ResidentRequestCard

export default {
  POST_LOGIN: 'POST_LOGIN',
  POST_LOGOUT: 'POST_LOGOUT',
  GET_PROFITS: 'GET_PROFITS',
  GET_ALL_COSTS: 'GET_ALL_COSTS',
  POST_FACILITY: 'POST_FACILITY',
  GET_BLOG_POST: 'GET_BLOG_POST',
  POST_REGISTER: 'POST_REGISTER',
  GET_ALL_UNITS: 'GET_ALL_UNITS',
  POST_CHECKUSER: 'POST_CHECKUSER',
  GET_FACILITIES: 'GET_FACILITIES',
  POST_MAKE_COST: 'POST_MAKE_COST',
  POST_RESIDENCES: 'POST_RESIDENCES',
  GET_COST_BUDGETS: 'GET_COST_BUDGETS',
  GET_CHARGE_RULES: 'GET_CHARGE_RULES',
  POST_OWNER_BLOCK: 'POST_OWNER_BLOCK',
  PATCH_OWNER_BLOCK: 'PATCH_OWNER_BLOCK',
  GET_PAYMENT_TOKEN: 'GET_PAYMENT_TOKEN',
  GET_FACILITY_TERMS: 'GET_FACILITY_TERMS',
  GET_MONTHLY_CHARGE: 'GET_MONTHLY_CHARGE',
  GET_USER_RESIDENCES: 'GET_USER_RESIDENCES',
  POST_RESIDENT_BLOCK: 'POST_RESIDENT_BLOCK',
  PATCH_ANNOUNCEMENTS: 'PATCH_ANNOUNCEMENTS',
  PATCH_RESIDENT_BLOCK: 'PATCH_RESIDENT_BLOCK',
  DELETE_ANNOUNCEMENTS: 'DELETE_ANNOUNCEMENTS',
  GET_FINANCIAL_SAVING: 'GET_FINANCIAL_SAVING',
  POST_EDITPROFILE_DATA: 'POST_EDITPROFILE_DATA',
  POST_NEW_ANNOUNCEMENTS: 'POST_NEW_ANNOUNCEMENTS',
  GET_USER_ANNOUNCEMENTS: 'GET_USER_ANNOUNCEMENTS',
  POST_VERIFICATION_CODE: 'POST_VERIFICATION_CODE',
  GET_BUILDING_FACILITIES: 'GET_BUILDING_FACILITIES',
  POST_MANAGER_SET_STATUS: 'POST_MANAGER_SET_STATUS',
  GET_MANAGER_FACILITIES_REQUEST: 'GET_MANAGER_FACILITIES_REQUEST',
  GET_RESIDENT_FACILITIES_REQUEST: 'GET_RESIDENT_FACILITIES_REQUEST',
}

/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/button-has-type */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// import { faChart, faBars } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Button from '../../../components/button/Button'

import PEN from '../../../assets/img/icons/pen.svg'
import FILE from '../../../assets/img/icons/file.svg'
import CARD from '../../../assets/img/icons/card.svg'
import HELP from '../../../assets/img/icons/help.svg'
import CHART from '../../../assets/img/icons/chart.svg'
import MONEY from '../../../assets/img/icons/money.svg'
import PEOPLE from '../../../assets/img/icons/people.svg'
import NOTIF from '../../../assets/img/icons/notif.svg'
import ADVERTISEMENT from '../../../assets/img/icons/advertisement.svg'

import ManagerHeader from '../../../components/managerHeader'

import './manager.scss'

// import CITY from '../../../assets/img/city.jpg'

export default function Resident(props) {
  const { t } = useTranslation()

  const routeMatch = useRouteMatch()

  const prefix = routeMatch.params.lang ? `/${routeMatch.params.lang}` : ''
  return (
    <div className='manager'>
      <div className='manager__box'>
        <ManagerHeader />

        <div className='manager__payment'>
          <div>
            <span>{t('charge-payment-this-month')}</span>
            <span>{t('total-number-of-building-units')}: 12</span>
          </div>
          <div>
            <div>
              <span>9 واحد </span>
            </div>
          </div>
        </div>

        <div className='manager__price'>
          <span>{t('remaining-unpaid-charge')}:</span>
          <span>
            <b>{props?.profit}</b> {t('toman')}
          </span>
        </div>
        <hr />
        <div className='manager__statistics'>
          <div>
            <div className='manager__statistics--disable'>
              <img alt='' src={PEN} />
            </div>
            <span>{t('building-information')}</span>
          </div>
          <div onClick={() => props.history.push(`${prefix}/units-list`)}>
            <div>
              <img alt='' src={PEOPLE} />
            </div>
            <span>{t('building-residents')}</span>
          </div>
          <div onClick={() => props.history.push(`${prefix}/building-costs`)}>
            <div>
              <img alt='' src={HELP} />
            </div>
            <span>{t('costs')}</span>
          </div>
          <div>
            <div className='manager__statistics--disable'>
              <img alt='' src={HELP} />
            </div>
            <span>{t('building-charge')}</span>
          </div>
          <div onClick={() => props.history.push(`${prefix}/financial-saving`)}>
            <div>
              <img alt='' src={MONEY} />
            </div>
            <span>{t('building-cash')}</span>
          </div>
          <div onClick={() => props.history.push(`${prefix}/announcements`)}>
            <div>
              <img alt='announcements' src={NOTIF} />
            </div>
            <span>{t('anouncemenets')}</span>
          </div>
          <div>
            <div className='manager__statistics--disable'>
              <img alt='' src={CHART} />
            </div>
            <span>{t('activity-report')}</span>
          </div>
          <div onClick={() => props.history.push(`${prefix}/manager-facilities`)}>
            <div>
              <img alt='facilities' src={CARD} />
            </div>
            <span>{t('shared-facilities')}</span>
          </div>
        </div>
        <hr />
        <div className='manager__statistics'>
          <div>
            <div className='manager__statistics--disable'>
              <img alt='' src={FILE} />
            </div>
            <span>{t('advertising')}</span>
          </div>
          <div>
            <div className='manager__statistics--disable'>
              <img alt='' src={ADVERTISEMENT} />
            </div>
            <span>{t('sale-and-rent')}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export function CostDetails(props) {
  const { color } = props
  return (
    <div className='box'>
      <div style={{ backgroundColor: color }}></div>
      <span>هزینه آب مشترک</span>
    </div>
  )
}

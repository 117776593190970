/* eslint-disable class-methods-use-this */

import Cookies from 'js-cookie'
import APIRouteManager from './APIRoutesManager'
import APIEndpointConstants from './ApiEndpointConstants'

// import APIRequestBodyGenerator from './APIRequestBodyGenerator'

import '@babel/polyfill'

class Service {
  constructor() {
    // this.token = Cookies.get('token')

    // default headers

    this.authenticateHeader = new Headers()
    this.authenticateHeader.set('Content-Type', 'application/json')
    this.authenticateHeader.set('accept', 'application/json')
  }

  // Base Fetch
  async fetchBase(url, config) {
    if (Cookies.get('token')) {
      this.authenticateHeader.set('Authorization', `Bearer ${Cookies.get('token')}`)
    }

    // handle fetch logic
    let fetchOptions = {}
    fetchOptions = {
      ...config,
      headers: this.authenticateHeader,
    }

    try {
      const response = await Promise.race([
        fetch(`${url}`, { ...fetchOptions }),
        new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              status: false,
              data: {
                code: 408,
                reason: 'TIME_OUT',
                message: 'خطایی رخ داده است لطفا مجددا تلاش کنید!',
              },
            })
          }, 40 * 1000)
        }),
      ])

      if (response.status === 401) {
        Cookies.remove('token')
      }

      if (response.ok === false) throw await response.json()

      // check timeout response...

      if (response.status === false && response.data.code === 408) throw response

      const result = await response.json()

      if (
        result.hasOwnProperty('error') || // auth "error"
        result.status === false
      ) {
        console.log('zzzzzzzzzzzzzzzzzzzzzzzv1')
        throw result
      } else return result
    } catch (e) {
      console.log(e)
      return {
        message: e.message,
      }
    }
  }

  GET_BlogPosts = (params) =>
    this.fetchBase(APIRouteManager.generateRoute({ key: APIEndpointConstants.GET_BLOG_POST, type: 'BLOG', params }), {
      method: 'GET',
    })

  POST_SendCode = (params) =>
    this.fetchBase(
      APIRouteManager.generateRoute({ key: APIEndpointConstants.POST_VERIFICATION_CODE, type: 'AUTH', params }),
      {
        method: 'POST',
      }
    )

  POST_Login = (params) =>
    this.fetchBase(APIRouteManager.generateRoute({ key: APIEndpointConstants.POST_LOGIN, type: 'AUTH', params }), {
      method: 'POST',
    })

  POST_Register = (params) =>
    this.fetchBase(APIRouteManager.generateRoute({ key: APIEndpointConstants.POST_REGISTER, type: 'AUTH', params }), {
      method: 'POST',
    })

  POST_Logout = (params) =>
    this.fetchBase(APIRouteManager.generateRoute({ key: APIEndpointConstants.POST_LOGOUT, type: 'AUTH', params }), {
      method: 'POST',
    })

  GET_FACILITIES = () =>
    this.fetchBase(APIRouteManager.generateRoute({ key: APIEndpointConstants.GET_FACILITIES, type: 'DASHBOARD' }), {
      method: 'GET',
    })

  GET_BUILDING_FACILITIES = ({ id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.GET_BUILDING_FACILITIES,
        type: 'DASHBOARD',
        urlParams: { id },
      }),
      {
        method: 'GET',
      }
    )

  GET_RESIDENT_FACILITIES_REQUEST = ({ id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.GET_RESIDENT_FACILITIES_REQUEST,
        type: 'DASHBOARD',
        urlParams: { id },
      }),
      {
        method: 'GET',
      }
    )

  GET_MANAGER_FACILITIES_REQUEST = ({ id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.GET_MANAGER_FACILITIES_REQUEST,
        type: 'DASHBOARD',
        urlParams: { id },
      }),
      {
        method: 'GET',
      }
    )

  POST_MANAGER_SET_STATUS = (param) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.POST_MANAGER_SET_STATUS,
        type: 'DASHBOARD',
        urlParams: { id: param.id },
      }),
      {
        method: 'POST',
        body: JSON.stringify(param.body),
      }
    )

  POST_REQUEST_FACILITY = (param) =>
    this.fetchBase(APIRouteManager.generateRoute({ key: APIEndpointConstants.POST_FACILITY, type: 'DASHBOARD' }), {
      method: 'POST',
      body: JSON.stringify(param.body),
    })

  GET_CHARGE_RULES = () =>
    this.fetchBase(APIRouteManager.generateRoute({ key: APIEndpointConstants.GET_CHARGE_RULES, type: 'DASHBOARD' }), {
      method: 'GET',
    })

  POST_RESIDENCES = (param) =>
    this.fetchBase(APIRouteManager.generateRoute({ key: APIEndpointConstants.POST_RESIDENCES, type: 'DASHBOARD' }), {
      method: 'POST',
      body: JSON.stringify(param.body),
    })

  GET_USER_RESIDENCES = () =>
    this.fetchBase(
      APIRouteManager.generateRoute({ key: APIEndpointConstants.GET_USER_RESIDENCES, type: 'DASHBOARD' }),
      {
        method: 'GET',
      }
    )

  // * ANNOUNCEMENTS START* //

  GET_USER_ANNOUNCEMENTS = ({ id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.GET_USER_ANNOUNCEMENTS,
        type: 'DASHBOARD',
        urlParams: { id },
      }),
      {
        method: 'GET',
      }
    )

  POST_NEW_ANNOUNCEMENTS = (param) =>
    this.fetchBase(
      APIRouteManager.generateRoute({ key: APIEndpointConstants.POST_NEW_ANNOUNCEMENTS, type: 'DASHBOARD' }),
      {
        method: 'POST',
        body: JSON.stringify(param.body),
      }
    )

  DELETE_ANNOUNCEMENTS = ({ id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.DELETE_ANNOUNCEMENTS,
        type: 'DASHBOARD',
        urlParams: { id },
        id,
      }),
      {
        method: 'DELETE',
      }
    )

  PATCH_ANNOUNCEMENTS = (param) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.PATCH_ANNOUNCEMENTS,
        type: 'DASHBOARD',
        urlParams: { id: param.id },
        id: param.id,
      }),
      {
        method: 'POST',
        body: JSON.stringify(param.body),
      }
    )

  // * ANNOUNCEMENTS END* //

  GET_FINANCIAL_SAVING = ({ id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.GET_FINANCIAL_SAVING,
        type: 'DASHBOARD',
        urlParams: { id },
      }),
      {
        method: 'GET',
      }
    )

  GET_ALL_COSTS = ({ id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({ key: APIEndpointConstants.GET_ALL_COSTS, type: 'DASHBOARD', urlParams: { id } }),
      {
        method: 'GET',
      }
    )

  GET_ALL_UNITS = ({ id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({ key: APIEndpointConstants.GET_ALL_UNITS, type: 'DASHBOARD', urlParams: { id } }),
      {
        method: 'GET',
      }
    )

  POST_RESIDENT_BLOCK = ({ body, id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.POST_RESIDENT_BLOCK,
        type: 'DASHBOARD',
        urlParams: { id },
        id,
      }),
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    )

  POST_OWNER_BLOCK = ({ body, id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.POST_OWNER_BLOCK,
        type: 'DASHBOARD',
        urlParams: { id },
        id,
      }),
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    )

  PATCH_OWNER_BLOCK = ({ body, id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.PATCH_OWNER_BLOCK,
        type: 'DASHBOARD',
        urlParams: { id },
        id,
      }),
      {
        method: 'PATCH',
        body: JSON.stringify(body),
      }
    )

  PATCH_RESIDENT_BLOCK = ({ body, id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.PATCH_RESIDENT_BLOCK,
        type: 'DASHBOARD',
        urlParams: { id },
        id,
      }),
      {
        method: 'PATCH',
        body: JSON.stringify(body),
      }
    )

  POST_UNIT_CONFIRM = ({ id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.POST_UNIT_CONFIRM,
        type: 'DASHBOARD',
        urlParams: { id },
      }),
      {
        method: 'POST',
      }
    )

  GET_COST_BUDGETS = () =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.GET_COST_BUDGETS,
        type: 'DASHBOARD',
      }),
      {
        method: 'GET',
      }
    )

  POST_MAKE_COST = ({ body, id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.POST_MAKE_COST,
        type: 'DASHBOARD',
        urlParams: { id },
      }),
      {
        method: 'POST',
        body: JSON.stringify(body),
      }
    )

  POST_EDITPROFILE_DATA = (params) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.POST_EDITPROFILE_DATA,
        type: 'DASHBOARD',
      }),
      {
        method: 'POST',
        body: JSON.stringify(params.body),
      }
    )

  GET_PROFITS = ({ id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.GET_PROFITS,
        type: 'DASHBOARD',
        urlParams: { id },
      }),
      {
        method: 'GET',
      }
    )

  GET_MONTHLY_CHARGE = ({ id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.GET_MONTHLY_CHARGE,
        type: 'DASHBOARD',
        urlParams: { id },
      })
    )

  GET_PAYMENT_TOKEN = ({ id }) =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.GET_PAYMENT_TOKEN,
        type: 'DASHBOARD',
        urlParams: { id },
      }),
      {
        method: 'POST',
      }
    )

  GET_FACILITY_TERMS = () =>
    this.fetchBase(
      APIRouteManager.generateRoute({
        key: APIEndpointConstants.GET_FACILITY_TERMS,
        type: 'DASHBOARD',
      }),
      {
        method: 'GET',
      }
    )
}

export default new Service()

/* eslint-disable no-unused-vars */
/* eslint-disable radix */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React, { lazy, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import Cookies from 'js-cookie'

import LOGO from '../../../assets/img/logo_white.svg'

import './Otp.scss'

import Service from '../../Api/Service'
import * as authActions from '../../redux/actions/AuthActions'
import * as SharedActions from '../../redux/actions/SharedActions'
import { useTranslation } from 'react-i18next'

const Button = lazy(() => import(/* webpackChunkName: "Button" */ '../../../components/button/Button'))
const OTPCode = lazy(() => import(/* webpackChunkName: "Button" */ './OTPCode'))

export default function Otp({
  history,
  match: {
    params: { lang },
  },
}) {
  const { t } = useTranslation()
  const prefix = lang ? `/${lang}` : ''

  // Local State
  const [counter, setCounter] = useState(null)
  const [validOtp, setValidOtp] = useState(false)
  const [waitingTime, setWaitingTime] = useState(20)
  const [autoFillOTPCodeSMS, setAutoFillOTPCodeSMS] = useState('')

  // Redux State
  const isAuth = useSelector((state) => state.auth.isAuth)

  const { type, telNumber, first_name, last_name } = history.location.state

  // Redux actions
  const dispatch = useDispatch()

  // Config
  const waitingTimeSeccond = 120
  const otpCodeCount = 6

  useEffect(() => {
    setWaitingTime(waitingTimeSeccond)
  }, [])

  useEffect(() => {
    if (isAuth === true) history.push(`${prefix}/switch-building`)
  }, [isAuth])

  // DidUpdate waitingTime
  useEffect(() => {
    if (waitingTime === waitingTimeSeccond) {
      setCounter(setInterval(() => setWaitingTime((waitingTime) => waitingTime - 1), 1000))
    } else if (waitingTime === 0) {
      clearInterval(counter)
      setWaitingTime(null)
    }
  }, [waitingTime])

  const handleOtpConfirm = async () => {
    if (waitingTime) {
      type === 'login' ? handleLogin() : handleRegister()
    } else {
      await Service.POST_SendCode({ type, mobile_number: telNumber })
      setWaitingTime(waitingTimeSeccond)
    }
  }

  // Did Update validOtp
  useEffect(() => {
    if (validOtp?.length === otpCodeCount && document.getElementById('confirmBtn')) {
      document.getElementById('confirmBtn').click()
    }
  }, [validOtp])

  const params = {
    token: validOtp,
    mobile_number: telNumber,
  }

  const registerParams = {
    last_name,
    first_name,
    token: validOtp,
    mobile_number: telNumber,
  }

  const handleLogin = async () => {
    const response = await Service.POST_Login(params)

    if (response.status === 200) {
      const { mobile_number, first_name, last_name } = response?.data

      dispatch(authActions.handleLoginData(response))
      Cookies.set('token', response.token)
      dispatch(authActions.handleUserInformation({ mobile: mobile_number, first_name, last_name, phone_number: '' }))
    } else {
      dispatch(
        SharedActions.setNotif({
          type: 'danger',
          message: `${response.message}`,
        })
      )
    }
  }

  const handleRegister = async () => {
    const response = await Service.POST_Register(registerParams)

    if (response.status === 200) {
      const { mobile_number, first_name, last_name } = response?.data

      dispatch(authActions.handleRegisterData(response))
      Cookies.set('token', response.token)
      dispatch(authActions.handleUserInformation({ mobile: mobile_number, first_name, last_name, phone_number: '' }))
    } else {
      dispatch(
        SharedActions.setNotif({
          type: 'danger',
          message: `${response.message}`,
        })
      )
    }
  }

  const inputsAreFull = validOtp?.length === otpCodeCount

  return (
    <div className='otp'>
      <div className='otp-box'>
        <div className='otp__header'>
          <img alt='logo' src={LOGO} />
        </div>

        <div className='otp__main'>
          <h2>{t('confirm-mobile-number')}</h2>
          <h3>{t('enter the 6-digit verification code in the box below')}</h3>

          <div className='otp__change'>
            <span>{telNumber}</span>
            <a href='/login'>{t('change-tel-number')}</a>
          </div>

          <OTPCode
            validNumber
            autoFillOTPCodeSMS={autoFillOTPCodeSMS}
            setValidOtp={setValidOtp}
            validOtp={validOtp}
            waitingTime={waitingTime}
          />

          <Button
            disabled={!inputsAreFull}
            handler={handleOtpConfirm}
            height='35px'
            id='confirmBtn'
            label={waitingTime ? `${t('confirm-mobile-number')}` : `${t('resent-password')}`}
            minWidth='50px'
            type={inputsAreFull ? 'primary' : 'disabled'}
            width='100%'
          />
        </div>
      </div>
    </div>
  )
}

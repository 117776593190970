/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import './unitCard.scss'

import {
  faBuilding,
  faEdit,
  faMinus,
  faPlus,
  faSpinner,
  faUser,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as SharedActions from '../../redux/actions/SharedActions'

import Modal from '../../../components/Modal/ModalPage'
import Button from '../../../components/button/Button'
import Input from '../../../components/input/Input'
import NumberInput from '../../../components/input/NumberInput'

import { useModal } from '../../hooks/useModal'
import Service from '../../Api/Service'

export default function UnitCard(props) {
  const UNIT_MODAL = 'UNIT_MODAL'

  const toggleModal = useModal(UNIT_MODAL)

  const [tab, setTab] = useState(0)
  const [telNumber, setTelNumber] = useState('')
  const [modalType, setModalType] = useState(0)
  const [blockStatus, setBlockStatus] = useState(false)
  const [cardIsExpanded, setCardExpanded] = useState(false)
  const [BuilPopulation, setBuilPopulation] = useState(0)
  const [personName, setPersonName] = useState('')
  const [personFamily, setPersonFamily] = useState('')

  // Redux actions
  const dispatch = useDispatch()

  useEffect(() => {
    setBuilPopulation(population || null)
    setTelNumber(tabConfig?.telNumber || '')
    setPersonName(tabConfig.firstName || '')
    setPersonFamily(tabConfig.lastName || '')
    setTelNumber(tabConfig.telNumber || '')
  }, [tab])

  const toggleCard = () => {
    setCardExpanded(!cardIsExpanded)
  }

  const {
    data: { id, title, population, owner, resident },
  } = props

  const card_state = `unit-card__body${cardIsExpanded ? '--expanded' : '--collapsed'}`

  var tabConfig = {}

  switch (tab) {
    case 0:
      tabConfig.state = resident
      tabConfig.text = !resident ? 'ثبت اطلاعات' : resident?.pivot?.confirmed ? 'تایید شده' : 'در انتظار تائید'
      tabConfig.icon = !resident ? faEdit : resident?.pivot?.confirmed ? faCheckCircle : faSpinner
      tabConfig.class = `status${!resident ? '--insert' : resident?.pivot?.confirmed ? '--approved' : '--pending'}`
      tabConfig.button = resident ? 'ویرایش' : 'افزودن اطلاعات'
      tabConfig.firstName = resident ? resident.first_name : null
      tabConfig.lastName = resident ? resident.last_name : null
      tabConfig.telNumber = resident ? resident.mobile_number : null

      break

    case 1:
      tabConfig.state = owner
      tabConfig.text = !owner ? 'ثبت اطلاعات' : owner?.pivot?.confirmed ? 'تایید شده' : 'در انتظار تایید'
      tabConfig.icon = !owner ? faEdit : owner?.pivot?.confirmed ? faCheckCircle : faSpinner
      tabConfig.class = `status${!owner ? '--insert' : owner?.pivot?.confirmed ? '--approved' : '--pending'}`
      tabConfig.button = owner ? 'ویرایش' : 'افزودن اطلاعات'
      tabConfig.firstName = owner ? owner.first_name : null
      tabConfig.lastName = owner ? owner.last_name : null
      tabConfig.telNumber = owner ? owner.mobile_number : null

      break

    default:
      break
  }

  const firstName = tab === 0 ? resident?.first_name : owner?.first_name
  const lastName = tab === 0 ? resident?.last_name : owner?.last_name
  const phoneNumber = tab === 0 ? resident?.mobile_number : owner?.mobile_number

  const addInfortmation = (state) => {
    setModalType(tab)
    toggleModal(true)
  }

  const numberInputsHandler = (type, name) => {
    if (type === 'inc' && BuilPopulation >= 0) setBuilPopulation(BuilPopulation + 1)
    if (type === 'dec' && BuilPopulation > 0) setBuilPopulation(BuilPopulation - 1)
  }

  const postResidentBlock = async () => {
    const bodyData = {
      first_name: personName,
      last_name: personFamily,
      mobile: telNumber,
      population: BuilPopulation,
    }

    const apiConf = { body: bodyData, id }
    const response = await Service.POST_RESIDENT_BLOCK(apiConf)
    if (response.status === 200) {
      dispatch(
        SharedActions.setNotif({
          type: 'success',
          message: `${response.message}`,
        })
      )
      setBlockStatus(true)
      toggleModal(false)
    } else {
      dispatch(
        SharedActions.setNotif({
          type: 'danger',
          message: `${response.message}`,
        })
      )
    }
  }

  const postOwnerBlock = async () => {
    const bodyData = {
      first_name: personName,
      last_name: personFamily,
      mobile: telNumber,
    }

    const apiConf = { body: bodyData, id }
    const response = await Service.POST_OWNER_BLOCK(apiConf)
    if (response.status === 200) {
      dispatch(
        SharedActions.setNotif({
          type: 'success',
          message: `${response.message}`,
        })
      )
      toggleModal(false)
    } else {
      dispatch(
        SharedActions.setNotif({
          type: 'danger',
          message: `${response.message}`,
        })
      )
    }
  }

  const patchOwnerBlock = async () => {
    const bodyData = {
      first_name: personName,
      last_name: personFamily,
      mobile: telNumber,
      user_id: owner.id,
    }

    const apiConf = { body: bodyData, id }
    const response = await Service.PATCH_OWNER_BLOCK(apiConf)
    if (response.status === 200) {
      dispatch(
        SharedActions.setNotif({
          type: 'success',
          message: `${response.message}`,
        })
      )
      toggleModal(false)
    } else {
      dispatch(
        SharedActions.setNotif({
          type: 'danger',
          message: `${response.message}`,
        })
      )
    }
  }

  const patchResidentBlock = async () => {
    const bodyData = {
      first_name: personName,
      last_name: personFamily,
      mobile: telNumber,
      population: BuilPopulation || tabConfig.population,
      user_id: resident.id,
    }

    const apiConf = { body: bodyData, id }
    const response = await Service.PATCH_RESIDENT_BLOCK(apiConf)
    if (response.status === 200) {
      dispatch(
        SharedActions.setNotif({
          type: 'success',
          message: `${response.message}`,
        })
      )
      toggleModal(false)
    } else {
      dispatch(
        SharedActions.setNotif({
          type: 'danger',
          message: `${response.message}`,
        })
      )
    }
  }
  const modalHeader = tab === 0 ? ' اطلاعات ساکن ' : ' اطلاعات مالک '

  const callModalRequest = () => {
    if (!tabConfig.state) {
      tab === 0 ? postResidentBlock() : postOwnerBlock()
    } else {
      tab === 0 ? patchResidentBlock() : patchOwnerBlock()
    }
  }

  return (
    <>
      <div className='unit-card'>
        <div className='unit-card__container'>
          <div className='unit-card__header'>
            <div className='unit-number'>
              <div className='unit-number__icon'>
                <FontAwesomeIcon icon={faBuilding} />
              </div>
              <span>{title}</span>
            </div>
            <div className={`status  ${tabConfig.class}`}>
              <div className='status__icon'>
                <FontAwesomeIcon icon={tabConfig.icon} />
              </div>
              <span> {tabConfig.text}</span>
            </div>
            <div className='expand-icon' onClick={toggleCard}>
              <FontAwesomeIcon icon={cardIsExpanded ? faMinus : faPlus} />
            </div>
          </div>
          <div className={`unit-card__body  ${card_state}`}>
            <div className='unit-card__tabs'>
              <button className={tab === 0 ? 'unit-card__active' : ''} onClick={() => setTab(0)}>
                <span>اطلاعات ساکن</span>
              </button>
              <button className={tab === 1 ? 'unit-card__active' : ''} onClick={() => setTab(1)}>
                <span>اطلاعات مالک</span>
              </button>
            </div>
            <div className='unit-card__body-line'></div>
            <div className='unit-card__body-info'>
              <div className='detail'>
                <div className='detail__subject'>
                  <span>نام کامل </span>
                  <span>تلفن همراه</span>
                </div>
                <div className='detail__data'>
                  <span>{firstName && lastName ? `${firstName} ${lastName}` : '--'}</span>
                  <span>{phoneNumber || '-'}</span>
                </div>
              </div>
              {tab === 0 && (
                <div className='members'>
                  <div className='members__icon'>
                    <FontAwesomeIcon icon={faUser} />
                  </div>
                  <span> {population || '-'} نفر </span>
                </div>
              )}
            </div>
            <div className='unit-card__body-btns'>
              <Button
                fontSize='13px'
                handler={() => addInfortmation(tabConfig.state)}
                height='35px'
                label={tabConfig.button}
                minWidth='120px'
                style={{ marginRight: '0px' }}
              />

              {tab === 0 && (
                <Button
                  fontSize='13px'
                  height='35px'
                  label='خروج از ساختمان'
                  minWidth='131px'
                  style={{ marginLeft: '0px' }}
                  type='bordered'
                />
              )}
            </div>
          </div>
        </div>
        <div className='unit-card__seperator'></div>

        <Modal name={UNIT_MODAL} title={`${title} - ${modalHeader}`} toggle={toggleModal}>
          <div className='anouncemenets__modal'>
            <Input
              handler={(e) => setTelNumber(e.target.value)}
              label='موبایل'
              name='phoneNumber'
              placeholder='شماره همراه '
              tag='input'
              type='number'
              value={telNumber}
            />
            <Input
              handler={(e) => setPersonName(e.target.value)}
              label='نام'
              name='name'
              placeholder='نام '
              tag='input'
              type='text'
              value={personName}
            />
            <Input
              handler={(e) => setPersonFamily(e.target.value)}
              label='نام خانوادگی'
              name='name'
              placeholder='نام خانوادگی'
              tag='input'
              type='text'
              value={personFamily}
            />
            {tab === 0 && (
              <NumberInput
                handleInputValue={(type, name) => numberInputsHandler(type, name)}
                label='تعداد ساکن ساختمان'
                name='blocks'
                value={BuilPopulation}
              />
            )}

            <Button
              handler={callModalRequest}
              height='35px'
              label='ذخیره'
              minWidth='130px'
              style={{
                marginTop: '30px',
              }}
              type='primary'
            />
          </div>
        </Modal>
      </div>
    </>
  )
}

/* eslint-disable no-unused-vars */

import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import ManagerHeader from '../../../components/managerHeader'
import ResidentHeader from '../../../components/residentHeader'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'

import { useTranslation } from 'react-i18next'

import './incomeReport.scss'

const mockUnits = [
  {
    id: 1,
    qty: 5,
    date: '11/6/2020',
    total_price: 5642000,
  },
  {
    id: 2,
    qty: 5,
    date: '11/6/2020',
    total_price: 5642000,
  },
  {
    id: 3,
    qty: 5,
    date: '11/6/2020',
    total_price: 5642000,
  },
  {
    id: 4,
    qty: 5,
    date: '11/6/2020',
    total_price: 5642000,
  },
  {
    id: 5,
    qty: 5,
    date: '11/6/2020',
    total_price: 5642000,
  },
  {
    id: 6,
    qty: 5,
    date: '11/6/2020',
    total_price: 5642000,
  },
  {
    id: 7,
    qty: 5,
    date: '11/6/2020',
    total_price: 5642000,
  },
  {
    id: 8,
    qty: 5,
    date: '11/6/2020',
    total_price: 5642000,
  },
]

const TableData = ({ units }) => {
  return units.map((unit, index) => {
    const { id, qty, date, total_price } = unit
    return (
      <tr key={id}>
        <td> {id} </td>
        <td> {qty} </td>
        <td> {date} </td>
        <td> {total_price} </td>
      </tr>
    )
  })
}

const TableHeader = ({ units }) => {
  let header = Object.keys(units[0])
  return header.map((key, index) => {
    return <th key={index}> {key.toUpperCase()} </th>
  })
}

function IncomeReport({
  history,
  match: {
    params: { lang },
  },
}) {
  const residenceId = useSelector((state) => state.shared.residence_id)
  const userType = useSelector((state) => state.shared.userType) || 'manager'
  const [units, setUnits] = useState(mockUnits)

  const { t } = useTranslation()

  return (
    <div className='report'>
      {userType === 'manager' ? <ManagerHeader /> : <ResidentHeader />}
      <div className='report__header'>
        <span> {t('income-report')} </span> {userType === 'manager'}
      </div>
      <div className='report__container'>
        <div className='report__report-card'>
          <div className='report__body'>
            <div className='report__filter'>
              <FontAwesomeIcon color='#999999' icon={faFilter} style={{ marginBottom: '5px' }} />
              <div className='report__select'> {t('select-unit')} </div>
            </div>
            <table className='report__units'>
              <tbody>
                <tr>
                  <TableHeader units={units} />
                </tr>
                <TableData units={units} />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default IncomeReport

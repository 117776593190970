/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable prefer-const */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/button-has-type */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {useTranslation} from 'react-i18next'
import { useHistory } from 'react-router-dom'

import Service from '../../Api/Service'

import Header from '../../../components/header/Header'
import Button from '../../../components/button/Button'
import SelectBox from '../../../components/selectBox'
import Input from '../../../components/input/Input'

import PROFILE from '../../../assets/img/bg.jpg'

import * as SharedActions from '../../redux/actions/SharedActions'
import * as AuthActions from '../../redux/actions/AuthActions'
import './editProfile.scss'

export default function EditProfile(props) {
  const {t} = useTranslation()
  // Redux State
  const userInformations = useSelector((state) => state.auth.userInformation)

  const [userInformation, setUserInformation] = useState({
    city_id: '',
    province_id: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    mobile: '',
  })

  useEffect(() => {
    console.log(userInformations)
    setUserInformation(userInformations)
  }, [])

  const history = useHistory()

  const { first_name, last_name, phone_number, mobile } = userInformation

  // Redux actions
  const dispatch = useDispatch()

  const handleSelectBoxItem = (item) => {
    console.log(item)
  }

  const items1 = [{ title: 'تهران', id: 1 }]

  const userInformationHandler = (e, name) => {
    let newInformation
    newInformation = { ...userInformation, [name]: e.target.value }
    setUserInformation(newInformation)
  }

  const callEditProfileApi = async () => {
    const bodyData = {
      mobile,
      last_name,
      first_name,
      city_id: 1,
      phone_number,
      province_id: 1,
    }

    const apiConf = { body: bodyData }
    const response = await Service.POST_EDITPROFILE_DATA(apiConf)
    if (response.status === 200) {
      dispatch(
        SharedActions.setNotif({
          type: 'success',
          message: `${response.message}`,
        })
      )

      dispatch(AuthActions.handleUserInformation(bodyData))
    } else
      dispatch(
        SharedActions.setNotif({
          type: 'danger',
          message: `${response.message}`,
        })
      )
  }

  return (
    <div className='edit-profile'>
      <Header />
      <div className='edit-profile__bio'>
        <div>
          <img alt='' src={PROFILE} />
          <h4>{`${userInformation.first_name} ${userInformation.last_name}`}</h4>
        </div>
      </div>
      <div className='edit-profile__container'>
        <div className='edit-profile__profile-card'>
          <div className='edit-profile__body'>
            <div className='edit-profile__section'>
              <Input
                handler={(e, name) => userInformationHandler(e, name)}
                label={t('name')}
                name='first_name'
                placeholder={t('enter-your-name')}
                type='text'
                value={first_name}
              />
            </div>
            <div className='edit-profile__section'>
              <Input
                handler={(e, name) => userInformationHandler(e, name)}
                label={t('last-name')}
                name='last_name'
                placeholder={t('enter-your-family')}
                type='text'
                value={last_name}
              />
            </div>
            <div className='edit-profile__section'>
              <Input
                handler={(e, name) => userInformationHandler(e, name)}
                label={t('phone')}
                name='phone_number'
                placeholder='021556988'
                type='number'
                value={phone_number}
              />
            </div>
            <div className='edit-profile__section'>
              <Input
                handler={(e, name) => userInformationHandler(e, name)}
                label={t('cell-phone')}
                name='mobile'
                placeholder='09354008622'
                type='number'
                value={mobile}
              />
            </div>
            <div className='edit-profile__seperator'></div>

            <div className='edit-profile__section'>
            <span> {t('residence')}</span>
              <div>
                <SelectBox
                  handleSelectedItem={(item) => handleSelectBoxItem(item)}
                  items={items1}
                  type='input'
                  width='105px'
                />
                <SelectBox
                  handleSelectedItem={(item) => handleSelectBoxItem(item)}
                  items={items1}
                  type='input'
                  width='105px'
                />
              </div>
            </div>
            <div className='edit-profile__section'>
              <Button
                fontSize='13px'
                handler={() => history.goBack()}
                height='35px'
                label={t('back')}
                type='bordered'
                width='128px'
              />
              <Button fontSize='13px' handler={callEditProfileApi} height='35px' label={t('save')} width='128px' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

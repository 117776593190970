import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import Header from '../../../components/header/Header'
import Input from '../../../components/input/Input'
import Button from '../../../components/button/Button'

import './AddGuest.scss'

function AddGuest({
  history,
  match: {
    params: { lang },
  },
}) {
  const { t } = useTranslation()
  const [validation, setValidation] = useState({
    fname: true,
    lname: true,
    vehicleType: true,
    plateNo: true,
    licenseType: true,
  })

  const [guestInformation, setGuestInformation] = useState({
    fname: '',
    lname: '',
    vehicleType: '',
    plateNo: '',
    licenseType: '',
    errormessage: '',
  })
  const { fname, lname, vehicleType, plateNo, licenseType } = guestInformation
  const guestInformationHandler = (e, name) => {
    let newInformation
    newInformation = { ...guestInformation, [name]: e.target.value }
    setGuestInformation(newInformation)
  }

  const makeNewguest = async () => {
    const bodyData = {
      fname,
      lname,
      vehicleType,
      plateNo,
      licenseType,
    }

    const apiConf = { body: bodyData }
    const inputsAreValid = fname && lname && vehicleType && plateNo && licenseType
    setValidation({
      firstname: !!fname,
      lastname: !!lname,
      vehicle: !!vehicleType,
      plate: !!plateNo,
      license: !!licenseType,
    })
    if (inputsAreValid) postRequest(apiConf)
  }
  const postRequest = async (apiConf) => {
    console.log('data send successfull')
  }

  return (
    <div className='register'>
      <Header />
      <div className='register__container'>
        <div className='register__register-card'>
          <div className='register__body'>
            <div className='register__section'>
              <Input
                handler={(e, name) => guestInformationHandler(e, name)}
                label={t('name')}
                name='fname'
                placeholder={t('name')}
                type='text'
                value={fname}
              />
            </div>
            {!validation.firstname && <span className='validation__message'>{t('is-required')}</span>}

            <div className='register__section'>
              <Input
                handler={(e, name) => guestInformationHandler(e, name)}
                label={t('last-name')}
                name='lname'
                placeholder={t('last-name')}
                type='text'
                value={lname}
              />
            </div>
            {!validation.lastname && <span className='validation__message'>{t('is-required')}</span>}

            <div className='register__section'>
              <Input
                handler={(e, name) => guestInformationHandler(e, name)}
                label={t('vehicle-type')}
                name='vehicleType'
                placeholder={t('vehicle-type')}
                type='text'
                value={vehicleType}
              />
            </div>
            {!validation.vehicle && <span className='validation__message'>{t('is-required')}</span>}

            <div className='register__section'>
              <Input
                handler={(e, name) => guestInformationHandler(e, name)}
                label={t('plate-no')}
                name='plateNo'
                placeholder={t('plate-no')}
                type='text'
                value={plateNo}
              />
            </div>
            {!validation.plate && <span className='validation__message'>{t('is-required')}</span>}

            <div className='register__section'>
              <Input
                handler={(e, name) => guestInformationHandler(e, name)}
                label={t('license-type')}
                name='licenseType'
                placeholder={t('license-type')}
                type='text'
                value={licenseType}
              />
            </div>
            {!validation.license && <span className='validation__message'>{t('is-required')}</span>}

            <div className='register__seperator'> </div>
            <div className='register__section'>
              <Button
                handler={() => history.push('/dashboard')}
                fontSize='13px'
                height='35px'
                label={t('dashboard')}
                type='bordered'
                width='128px'
              />
              <Button handler={makeNewguest} fontSize='13px' height='35px' label={t('save')} width='128px' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default AddGuest

import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'

// import { useHistory } from 'react-router-dom'
import ManagerHeader from '../../../components/managerHeader'

import './unitsList.scss'
import Header from '../../../components/header/Header'
import Button from '../../../components/button/Button'
// import CITY from '../../../assets/img/city.jpg'
import UnitCard from './UnitCard'

import Service from '../../Api/Service'

export default function UnitsList({
  history,
  match: {
    params: { lang },
  },
}) {
  const prefix = lang ? `/${lang}` : ''

  const [data, setData] = useState([])

  // const mockData = [
  //   { unitNumber: '1', id: '1', status: 'approved', name: 'حاج امیر زندی', phone: '09121111111', members: '5' },
  //   { unitNumber: '2', id: '2', status: 'pending', name: 'پیمان شهبازی ', phone: '09121111111', members: '3' },
  //   { unitNumber: '3', id: '3', status: 'insert', name: 'لیونل مسی', phone: '09121111111', members: '4' },
  //   { unitNumber: '4', id: '4', status: 'approved', name: 'مجتبی جباری ', phone: '09121111111', members: '6' },
  //   { unitNumber: '5', id: '5', status: 'pending', name: 'شیخ دیاباته ', phone: '09121111111', members: '8' },
  // ]

  // Redux State
  const residence_id = useSelector((state) => state.shared.residence_id)

  useEffect(() => {
    callUnits()
  }, [])

  const callUnits = async () => {
    const response = await Service.GET_ALL_UNITS({ id: residence_id })
    if (response.status === 200) {
      setData(response.data)
    }
  }
  const { t } = useTranslation()

  return (
    <div className='unit-list'>
      <Header />
      <ManagerHeader />
      <div className='anouncemenets__header'>
        <span>{t('residents-information')}</span>
      </div>

      <div className='unit-list__body'>
        {data?.map((item) => {
          return (
            <React.Fragment key={item.id}>
              <UnitCard renderPage data={item} />
            </React.Fragment>
          )
        })}
      </div>
      <Button
        handler={() => history.push(`${prefix}/dashboard`)}
        height='35px'
        label={t('dashboard')}
        minWidth='200px'
        style={{
          marginTop: '30px',
        }}
        type='primary'
      />
    </div>
  )
}

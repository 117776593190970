import React, { lazy } from 'react'
import { useTranslation } from 'react-i18next'

import LOGO from '../../../assets/img/icons/logo_color.svg'
// import GOOGLE from '../../../assets/img/facebook-login.svg'
// import FACEBOOK from '../../../assets/img/google-login.svg'

import './home.scss'
import Flags from '../../../components/flags'

const Button = lazy(() => import(/* webpackChunkName: "Button" */ '../../../components/button/Button'))

export default function Home({
  history,
  match: {
    params: { lang },
  },
}) {
  const { t } = useTranslation()

  const prefix = lang ? `/${lang}` : ''

  return (
    <div className='home'>
      <div className='home__header'>
        <Flags />
        <img alt='' src={LOGO} />
        <span>{t('building-management-application')}</span>
      </div>
      <div className='home__footer'>
        <Button
          handler={() => history.push(`${prefix}/signup`)}
          height='50px'
          label={t('new-user')}
          minWidth='50px'
          type='primary'
          width='250px'
        />
        <Button
          handler={() => history.push(`${prefix}/login`)}
          height='50px'
          label={t('users-sign-in')}
          minWidth='50px'
          type='primary'
          width='250px'
        />
        <div className='home__copyright'>
          <span>
            {' '}
            &copy;1399 {t('all-rights')} <br />
            {t('this-app-belongs-to-iBillding')}
          </span>
        </div>
      </div>
    </div>
  )
}
